<form [formGroup]="entity">
  <div class="row p-4">
    <div class="col-12 text-center mb-5">
      <p>Queremos conocerte un poco más</p>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="firstName">Nombre(s)</label>
        <input type="text" pInputText id="firstName" formControlName="firstName" placeholder="Miguel Armando" required />
        <ng-container *ngIf="entity.get('firstName')?.invalid && (entity.get('firstName')?.dirty || entity.get('firstName')?.touched)">
          <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['required']">El nombre es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('firstName')?.errors?.['minlength']">El nombre debe tener al menos {{entity.get('firstName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="lastName">Apellidos</label>
        <input type="text" pInputText id="lastName" formControlName="lastName" placeholder="Roldan Flores" required />
        <ng-container *ngIf="entity.get('lastName')?.invalid && (entity.get('lastName')?.dirty || entity.get('lastName')?.touched)">
          <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['required']">Los apellidos son obligatorios.</small>
          <small class="p-error" *ngIf="entity.get('lastName')?.errors?.['minlength']">Los apellidos deben tener al menos {{entity.get('lastName')?.errors?.['minlength']['requiredLength']}} caracteres.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="rfc">RFC</label>
        <input type="text" pInputText id="rfc" formControlName="rfc" placeholder="XXXX900909XX1" (keydown)="validateRFC()" required />
        <ng-container *ngIf="entity.get('rfc')?.invalid && (entity.get('rfc')?.dirty || entity.get('rfc')?.touched)">
          <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['required']">El rfc es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('rfc')?.errors?.['pattern']">El rfc no es válido.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="curp">CURP</label>
        <input type="text" pInputText id="curp" formControlName="curp" placeholder="XXXX900909HDFRRW01" (keydown)="validateCURP()" required />
        <ng-container *ngIf="entity.get('curp')?.invalid && (entity.get('curp')?.dirty || entity.get('curp')?.touched)">
          <small class="p-error" *ngIf="entity.get('curp')?.errors?.['required']">El CURP es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('curp')?.errors?.['pattern']">El CURP no es válido.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="civilStatus">Estado civil:*</label>
        <p-dropdown formControlName="civilStatus" [panelStyle]="{'width': '100%' }" [options]="$_maritalStatus" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        <ng-container *ngIf="entity.get('civilStatus')?.invalid && (entity.get('civilStatus')?.dirty || entity.get('civilStatus')?.touched)">
          <small class="p-error" *ngIf="entity.get('civilStatus')?.errors?.['required']">El estado civil es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="nationality">Nacionalidad:*</label>
        <p-dropdown formControlName="nationality" [panelStyle]="{'width': '100%' }" [options]="$_nationalitiesStatus" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        <ng-container *ngIf="entity.get('nationality')?.invalid && (entity.get('nationality')?.dirty || entity.get('nationality')?.touched)">
          <small class="p-error" *ngIf="entity.get('nationality')?.errors?.['required']">El estado civil es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-5">
        <label for="employeeNumber">Numero de empleado: (opcional)</label>
        <input type="text" pInputText id="employeeNumber" formControlName="employeeNumber" placeholder="69918212" />
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-5">
        <label for="advisor">Asesor: (opcional)</label>
        <input type="text" pInputText id="advisor" formControlName="advisor" placeholder="01" />
      </div>
    </div>
    <div class="col-12 flex flex-column">
      <button pButton pRipple label="Continuar" class="p-button-primary button-block mb-3" (click)="sendData()" [disabled]="entity.invalid"></button>
      <button pButton pRipple label="Contáctanos por Whatsapp" class="p-button-primary p-button-outlined button-block mb-3" (click)="whatsapp()"></button>
    </div>
  </div>
</form>
