import { Component } from '@angular/core';

@Component({
  selector: 'app-detail-benefit',
  templateUrl: './detail-benefit.component.html',
  styleUrls: ['./detail-benefit.component.scss']
})
export class DetailBenefitComponent {

}
