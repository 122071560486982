import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../../data/services/modules/auth/auth.service";
import {Message} from "primeng/api";
import {Router} from "@angular/router";

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent {
  entity: FormGroup
  showMessage = false
  responseMessage : Message[] = []
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.entity = this.fb.group({
      emailAddress: [ '', [Validators.required,Validators.pattern(/^[a-zA-Z0-9_.+]*[a-zA-Z][a-zA-Z0-9_.+]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]],
      password: [ '', [Validators.required]]
    })
  }

  login() {
    this.showMessage = false
    this.authService.login(this.entity.value).subscribe(r => {
      if (r.error) {
        this.showMessage = true
        this.responseMessage = [
          {
            summary: r.msg,
            severity: 'error',
            closable: true
          }
        ]
      } else if (!r.error) {
        this.authService.updateUserLocalData(r.data)
        this.router.navigate(['/'])
      }
    })
  }
}
