import {Component, OnInit} from '@angular/core';
import {faEye, faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {ApiWithdrawalService} from "../../../../../data/services/api/withdrawal/api-withdrawal.service";
import {MOVEMENT_ENUM, MOVEMENT_ENUM_COLOR} from "../../../../../data/enum/movements.enum";
import {LoanService} from "../../../../../data/services/api/loan/loan.service";
import {LoaderService} from "../../../../../data/services/page/loader.service";

@Component({
  selector: 'app-balance-movements',
  templateUrl: './balance-movements.component.html',
  styleUrls: ['./balance-movements.component.scss']
})
export class BalanceMovementsComponent implements OnInit {
  typeProduct = 'WITHDRAWAL'
  folio = ''
  typeProducts = [
    {label: 'Retiro', value: 'WITHDRAWAL'},
    {label: 'Crédito', value: 'LOAN'},
  ]
  $_movementEnum = MOVEMENT_ENUM
  $_movementEnumColor = MOVEMENT_ENUM_COLOR
  movements: {id: string, folio: string; date: Date, typeProduct: string; statusPaid: string; statusDispersion: string; dispersedAmount: number; amountPaid: number; fee: number;}[] = [
    /*{id: 'abc', folio: 'ABCDE1', date: new Date(), typeProduct: 'Retiro', statusPaid: 'Pendiente de pago', statusDispersion: 'Dispersado', dispersedAmount: 1200, amountPaid: 36},
    {id: 'abc', folio: 'ABCDE2', date: new Date(), typeProduct: 'Crédito', statusPaid: 'Pendiente de pago', statusDispersion: 'Dispersado', dispersedAmount: 10000, amountPaid: 1200},
    {id: 'abc', folio: 'ABCDE2', date: new Date(), typeProduct: 'Crédito', statusPaid: 'Pendiente de pago', statusDispersion: 'Dispersado', dispersedAmount: 10000, amountPaid: 1200},
    {id: 'abc', folio: 'ABCDE2', date: new Date(), typeProduct: 'Crédito', statusPaid: 'Pendiente de pago', statusDispersion: 'Dispersado', dispersedAmount: 10000, amountPaid: 1200},
    {id: 'abc', folio: 'ABCDE2', date: new Date(), typeProduct: 'Crédito', statusPaid: 'Pendiente de pago', statusDispersion: 'Dispersado', dispersedAmount: 10000, amountPaid: 1200},
    {id: 'abc', folio: 'ABCDE2', date: new Date(), typeProduct: 'Crédito', statusPaid: 'Pendiente de pago', statusDispersion: 'Dispersado', dispersedAmount: 10000, amountPaid: 1200},*/
  ]
  protected readonly faEye = faEye;
  protected readonly faUpRightFromSquare = faUpRightFromSquare;

  constructor(
    private withdrawalService: ApiWithdrawalService,
    private loanService: LoanService,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.getWithdrawals()
  }

  get getMovements () {
    if (this.folio === '') return this.movements
    return this.movements.filter(i => i.folio.includes(this.folio) || i.dispersedAmount.toString().includes(this.folio))
  }
  getWithdrawals() {
    this.loaderService.showSpinner()
    this.withdrawalService.getAll().subscribe(r => {
      this.loaderService.hideSpinner()
      if (!r.error) {
        r.data = r.data.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        this.movements = r.data.map(m => {
          return {
            id: m._id,
            date: new Date(new Date(m.date).getTime() + (60 * 60 * 6 * 1000)),
            statusDispersion: m.status,
            statusPaid: (m.collectedAmount >= (m.fee + m.amount - 0.1)) ? 'PAID' : 'IN_PROCESS',
            amountPaid: m.collectedAmount,
            typeProduct: 'Retiro',
            folio: m.folio,
            dispersedAmount: m.amount,
            fee: m.fee
          }
        })
      }
    })
  }

  getLoans() {
    this.loaderService.showSpinner()
    this.loanService.getAll().subscribe(r => {
      this.loaderService.hideSpinner()
      if (!r.error) {
        r.data = r.data.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        this.movements = r.data.map(m => {
          return {
            id: m._id,
            date: new Date(new Date(m.date).getTime() + (60 * 60 * 6 * 1000)),
            statusDispersion: m.status,
            statusPaid: (m.collectedAmount >= (m.fee + m.amount - 0.1)) ? 'PAID' : 'IN_PROCESS',
            amountPaid: m.collectedAmount,
            typeProduct: 'Crédito',
            folio: m.folio,
            dispersedAmount: m.amount,
            fee: m.fee
          }
        })
      }
    })
  }

  findProducts() {
    console.log(this.typeProduct)
    if (this.typeProduct === 'WITHDRAWAL') this.getWithdrawals()
    else this.getLoans()
  }

}
