<div class="card-sod">
  <ng-container *ngIf="data; else elseData">
    <div class="card-sod-header">
      <div class="card-sod-header_date">
        <fa-icon [icon]="faBriefcase"></fa-icon>
        <div class="card-sod-header_date-info">
          <h5>Dias trabajados</h5>
          <h4>{{data.sodProperties.accruedDays}}</h4>
        </div>
      </div>
      <div class="card-sod-header_date">
        <fa-icon [icon]="faCalendarDays"></fa-icon>
        <div class="card-sod-header_date-info">
          <h5>Fecha de corte</h5>
          <h4>{{(data.sodProperties.endDate | date:'dd LLL') | titlecase}}</h4>
        </div>
      </div>
    </div>
    <div class="card-sod-bar">
      <div class="bar">
        <div class="bar-withdrawn" [style]="{width: getPercents.withdrawn+'%', left: getPercents.available+'%'}"></div>
        <div class="bar-available" [style]="{width: getPercents.available+'%'}"></div>
      </div>
    </div>
    <div class="card-sod-footer">
      <div class="card-sod-footer_bartag">
        <fa-icon [icon]="faCircle"></fa-icon>
        <div class="card-sod-footer_bartag-info">
          <h6>Disponible</h6>
          <h5>{{data.sodProperties.availableAppSalary | currency:'MXN'}}</h5>
        </div>
      </div>
      <div class="card-sod-footer_bartag">
        <fa-icon [icon]="faCircle"></fa-icon>
        <div class="card-sod-footer_bartag-info">
          <h6>Retirado</h6>
          <h5>{{data.sodProperties.amountWithdrawn | currency: 'MXN'}}</h5>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #elseData>
    <div class="flex flex-row justify-content-between">
      <div style="width:45%">
        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
      </div>
      <div style="width:45%">
        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
      </div>
    </div>
    <div class="flex flex-row justify-content-between">
      <div style="width:45%">
        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
      </div>
      <div style="width:40%">
        <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="75%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="75%"></p-skeleton>
  </ng-template>
</div>
