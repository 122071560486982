import {Component, OnInit} from '@angular/core';
import {ApiFlowService} from "../../../../data/services/api/flow/api-flow.service";
import {IFlow, IFlowByStages} from "../../../../data/interfaces/iflow.interface";

@Component({
  selector: 'app-verify-information',
  templateUrl: './verify-information.component.html',
  styleUrls: ['./verify-information.component.scss']
})
export class VerifyInformationComponent implements OnInit {
  data!: IFlowByStages
  titleSection = 'Verifica tu información'
  backRoute = '/'
  screenVerificationList = true
  currentScreenType = 'FORM'
  currentScreenData!: IFlow
  constructor(
    private flowService: ApiFlowService
  ) {
  }

  ngOnInit() {
    this.getFlow()
  }

  getFlow() {
    this.flowService.getFlow().subscribe(r => {
      console.log(r)
      if(!r.error) this.data = r.data
    })
  }

  retryFlow() {
    this.getFlow()
  }

  showScreen(stage: IFlow) {
    console.log(stage)
    console.log("data", this.data)
    this.screenVerificationList = false
    this.backRoute = '/verify-documents'
    this.titleSection = stage.properties.title
    this.currentScreenType = stage.properties.type
    this.currentScreenData = stage
  }

  showVerificationScreen (retryFlow: false) {
    this.screenVerificationList = true
    this.backRoute = '/'
    this.titleSection = 'Verifica tu información'
    if (retryFlow) this.getFlow()
  }
}
