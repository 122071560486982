import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-list-documents',
  templateUrl: './verify-list-documents.component.html',
  styleUrls: ['./verify-list-documents.component.scss']
})
export class VerifyListDocumentsComponent {

}
