import {Component, OnInit} from '@angular/core';
import {faCreditCard, faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import {AccountService} from "../../../../data/services/api/accounts/account.service";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {LoaderService} from "../../../../data/services/page/loader.service";

@Component({
  selector: 'app-list-accounts',
  templateUrl: './list-accounts.component.html',
  styleUrls: ['./list-accounts.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ListAccountsComponent implements OnInit {
  responseMessage: Message[] = []
  showMessage = false
  accounts: {id: string; account: string; owner: string;}[] = []
  protected readonly faFileInvoice = faFileInvoice;
  protected readonly faCreditCard = faCreditCard;

  constructor(
    private accountService: AccountService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.getAccounts()
  }

  confirm(id: string) {
    this.confirmationService.confirm({
      header: 'Seguro que deseas eliminar la cuenta?',
      message: 'Da click en eliminar para continuar.',
      acceptLabel: 'Eliminar',
      rejectLabel: 'Cancelar',
      acceptButtonStyleClass: 'p-button-danger',
      rejectButtonStyleClass: 'p-button-outlined p-button-primary',
      accept: () => {
        this.deleteAccount(id)
      },
      reject: () => {

      }
    });
  }

  getAccounts () {
    this.accountService.getAll().subscribe(r => {
      const accounts: {id: string; account: string; owner: string;}[] = []
      if (!r.error) {
        r.data.forEach(a => {
          accounts.push({
            id: a._id,
            account: `${a.bank} *${a.lastFour}`,
            owner: a.owner ?? ''
          })
        })

      }
      this.accounts = accounts
    })
  }
  deleteAccount(id: string) {
    this.showMessage = false
    this.loaderService.showSpinner()
    this.accountService.delete(id).subscribe(r => {
      this.loaderService.hideSpinner()
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ? 'error': 'success',
          closable: false,
        }
      ]
      this.showMessage = true
      if(!r.error) this.getAccounts()
      setTimeout(() => {
        this.showMessage = false
        this.responseMessage = []
      }, 3000)
    })
  }
}
