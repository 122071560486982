import {Component, EventEmitter, Input, Output} from '@angular/core';
import {faBank, faTrash} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-accounts-account',
  templateUrl: './accounts-account.component.html',
  styleUrls: ['./accounts-account.component.scss']
})
export class AccountsAccountComponent {

  protected readonly faBank = faBank;
  protected readonly faTrash = faTrash;
  @Input() account = ''
  @Input() owner = ''
  @Input() id = ''
  @Output() onClick = new EventEmitter()
}
