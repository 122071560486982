import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  faAngleRight,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircle,
  faCircleQuestion
} from "@fortawesome/free-solid-svg-icons";
import {IFlowByStages} from "../../../../../data/interfaces/iflow.interface";

@Component({
  selector: 'app-verification-documents-list',
  templateUrl: './verification-documents-list.component.html',
  styleUrls: ['./verification-documents-list.component.scss']
})
export class VerificationDocumentsListComponent {
  @Input() data!: IFlowByStages
  @Output() onClick = new EventEmitter()
  protected readonly faCircle = faCircle;
  protected readonly faAngleRight = faAngleRight;
  protected readonly faChevronRight = faChevronRight;
  protected readonly faCheck = faCheck;
  protected readonly faCircleQuestion = faCircleQuestion;
  protected readonly faCheckCircle = faCheckCircle;
}
