import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-home-screen',
  templateUrl: './auth-home-screen.component.html',
  styleUrls: ['./auth-home-screen.component.scss']
})
export class AuthHomeScreenComponent {

}
