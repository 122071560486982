import { Injectable } from '@angular/core';
import {ApiClass} from "../../class/api-class";
import {map} from "rxjs";
import {IWorkerProperties} from "../../../modules/private/home/interfaces/worker.interface";
import {IOnboarding} from "../../interfaces/ionboarding.interface";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WorkerService extends ApiClass {

  constructor() {
    super()
    this.path = 'worker'
  }

  getUserProperties() {
    return this.getCollection().pipe(map((r) => r as {error: boolean; msg:string; data: IWorkerProperties}))
  }

  createAccount(data: any) {
    return this.postCollection(data, 'onboarding').pipe(map((r) => r as {error: boolean; msg: string; data: IOnboarding }))
  }

  getProducts() {
    return this.getCollection('', environment.apiUrl, 'worker/loanProducts').pipe(map((r) => r as {error: boolean; msg: string; data: {id: string; name: string; mainPoints: string[]}[]}))
  }
}
