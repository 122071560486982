import {Component, Input} from '@angular/core';
import {faCircleUser, faTags} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../../data/services/modules/auth/auth.service";

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {

    protected readonly faCircleUser = faCircleUser;
    @Input() title = ''
    @Input() subtitleRight = ''
    @Input() subtitleLeft = ''
    @Input() icon = faTags
  constructor(
    public authService: AuthService
  ) {
  }
}
