<div class="header-navigation" [style]="{background: background, color: color}">
  <ng-container *ngIf="backRoute !== '';else elseBackRoute">
    <div  class="icon-action" [routerLink]="backRoute"  (click)="onClick.emit()">
      <fa-icon [icon]="icons.back"></fa-icon>
    </div>
  </ng-container>
  <ng-template #elseBackRoute>
    <div class="px-1"></div>
  </ng-template>
  <h4>{{title}}</h4>
  <ng-container *ngIf="closeRoute !== ''; else elseIcon">
    <div class="icon-action"  [routerLink]="closeRoute">
      <fa-icon [icon]="rightIcon"></fa-icon>
    </div>

  </ng-container>
  <ng-template #elseIcon>
    <div class="px-3"></div>
  </ng-template>
</div>
