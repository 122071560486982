import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {MARRIAGE_STATUS_CONST, NATIONALITIES_CONST} from "../../data/constants/onboarding.const";

@Component({
  selector: 'app-onboarding-general-information',
  templateUrl: './onboarding-general-information.component.html',
  styleUrls: ['./onboarding-general-information.component.scss']
})
export class OnboardingGeneralInformationComponent {
  $_maritalStatus = MARRIAGE_STATUS_CONST
  $_nationalitiesStatus = NATIONALITIES_CONST
  @Input() entity!: FormGroup
  @Output() onSendData = new EventEmitter()

  constructor() {

  }

  sendData() {
    this.onSendData.emit()
  }

  validateRFC() {
    console.log(this.entity.value.rfc)
    const rfcControl = this.entity.get('rfc');
    if (rfcControl) {
      const cleanedRFC = rfcControl.value.trim();
      rfcControl.setValue(cleanedRFC);
      if (cleanedRFC.length !== 13) {
        rfcControl.setErrors({length: true});
      } else {
        rfcControl.updateValueAndValidity();
      }
    }
  }

  validateCURP() {
    console.log(this.entity.value.curp)
    const curpControl = this.entity.get('curp');
    if (curpControl) {
      const cleanedCURP = curpControl.value.trim();
      curpControl.setValue(cleanedCURP);
      if (cleanedCURP.length !== 13) {
        curpControl.setErrors({length: true});
      } else {
        curpControl.updateValueAndValidity();
      }
    }
  }


  whatsapp() {
    window.open('https://wa.me/525580284233?text=Hola+necesito+ayuda', '_blank')
  }
}
