import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFlow} from "../../../../../data/interfaces/iflow.interface";
import {Message} from "primeng/api";
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {LoaderService} from "../../../../../data/services/page/loader.service";

@Component({
  selector: 'app-external-component',
  templateUrl: './external-component.component.html',
  styleUrls: ['./external-component.component.scss']
})
export class ExternalComponentComponent implements OnInit {
  responseMessage: Message[] = []
  showMessage = false
  startButtonIsAvailable = false
  @Input() data!: IFlow
  @Output() onEndProcess = new EventEmitter()
  currentUrl = ''
  startedProcess = false
  settings = {description: '', img: '', url: ''}

  constructor(
    private flowDataService: ApiFlowDataService,
    private loaderService: LoaderService
  ) {
  }

  ngOnInit() {
    this.settings = JSON.parse(this.data.properties.settings)
    if (this.settings.url && this.settings.url !== '') this.data.value = this.settings.url
    console.log("hola", this.data)
  }

  open() {
    console.log(this.data)
    if (this.data.value) {
      window.open(this.data.value, '_blank')
      this.startedProcess = true
    }
  }

  validateExternalLink() {
    this.loaderService.showSpinner()
    this.flowDataService.validateExternalUrl({idDocument: this.data.id, idStage: this.data.userDocumentId}).subscribe(r => {
      this.loaderService.hideSpinner()
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ?  'error': 'success',
          closable: false,
        }
      ]
      if (!r.error) {
        setTimeout(() => {
          this.onEndProcess.emit(true)
        }, 2000)
      }
    })
  }
}
