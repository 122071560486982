import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthLoginComponent} from "./modules/public/auth/auth-login/auth-login.component";
import {OnboardingProcessComponent} from "./modules/public/onboarding/onboarding-process/onboarding-process.component";
import {HomeScreenComponent} from "./modules/private/home/home-screen/home-screen.component";
import {
  WithdrawalCreateNewComponent
} from "./modules/private/withdrawals/withdrawal-create-new/withdrawal-create-new.component";
import {ListBenefitsComponent} from "./modules/private/benefits/list-benefits/list-benefits.component";
import {ListAccountsComponent} from "./modules/private/accounts/list-accounts/list-accounts.component";
import {AccountsCreateComponent} from "./modules/private/accounts/accounts-create/accounts-create.component";
import {BalanceStateComponent} from "./modules/private/balance/balance-state/balance-state.component";
import {WithdrawalDetailComponent} from "./modules/private/withdrawals/withdrawal-detail/withdrawal-detail.component";
import {LoanDetailComponent} from "./modules/private/loan/loan-detail/loan-detail.component";
import {
  VerifyInformationComponent
} from "./modules/private/verify-information/verify-information/verify-information.component";
import {AuthGuard} from "./core/auth.guard";
import {NoAuthGuard} from "./core/no-auth.guard";
import {AuthHomeScreenComponent} from "./modules/public/auth/auth-home-screen/auth-home-screen.component";
import {RestorePasswordComponent} from "./modules/public/auth/restore-password/restore-password.component";
import {ProfileUserComponent} from "./modules/private/profile/profile-user/profile-user.component";
import {LoanCreateComponent} from "./modules/private/loan/loan-create/loan-create.component";

const routes: Routes = [
  {
    path: '',
    component: AuthHomeScreenComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'login',
    component: AuthLoginComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'onboarding',
    component: OnboardingProcessComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'reset-password',
    component: RestorePasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'home',
    component: HomeScreenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'loan',
    children: [
      {
        path: '',
        component: LoanCreateComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: LoanDetailComponent,
        canActivate: [AuthGuard],
      }
    ]
  },
  {
    path: 'withdrawal',
    children: [
      {
        path: '',
        component: WithdrawalCreateNewComponent,
        canActivate: [AuthGuard]
      },
      {
        path: ':id',
        component: WithdrawalDetailComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'benefits',
    component: ListBenefitsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileUserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'accounts',
    children: [
      {
        path: '',
        component: ListAccountsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'create',
        component: AccountsCreateComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'balance',
    children: [
      {
        path: '',
        component: BalanceStateComponent,
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'verify-documents',
    component: VerifyInformationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
