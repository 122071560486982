import {Component, OnInit} from '@angular/core';
import {faMoneyBillTransfer, faScaleBalanced} from "@fortawesome/free-solid-svg-icons";
import {ActivatedRoute} from "@angular/router";
import {ApiWithdrawalService} from "../../../../data/services/api/withdrawal/api-withdrawal.service";
import {IWithdrawalResume} from "../../../../data/interfaces/iwithdrawal.interface";
import {MOVEMENT_ENUM, MOVEMENT_ENUM_COLOR} from "../../../../data/enum/movements.enum";

@Component({
  selector: 'app-withdrawal-detail',
  templateUrl: './withdrawal-detail.component.html',
  styleUrls: ['./withdrawal-detail.component.scss']
})
export class WithdrawalDetailComponent implements OnInit {
    id: string
    protected readonly faScaleBalanced = faScaleBalanced;
    protected readonly faMoneyBillTransfer = faMoneyBillTransfer;
    movement!: IWithdrawalResume
  $_movementEnum = MOVEMENT_ENUM
  $_movementEnumColor = MOVEMENT_ENUM_COLOR
    constructor(
      private activeRoute: ActivatedRoute,
      private withdrawalService: ApiWithdrawalService
    ) {
      this.id = this.activeRoute.snapshot.paramMap.get('id') as string
      console.log(this.id)
    }

    ngOnInit() {
      this.getData()
    }

    getData() {
      this.withdrawalService.getById(this.id).subscribe(r => {
        console.log(r)
        if (!r.error) {
          this.movement = r.data
        }
      })
    }
}
