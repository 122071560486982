import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, Message, MessageService} from "primeng/api";
import {UserService} from "../../../../data/services/page/user.service";
import {AccountService} from "../../../../data/services/api/accounts/account.service";
import {IWithdrawal} from "../../../../data/interfaces/iwithdrawal.interface";
import {ApiWithdrawalService} from "../../../../data/services/api/withdrawal/api-withdrawal.service";
import {LoaderService} from "../../../../data/services/page/loader.service";

@Component({
  selector: 'app-withdrawal-create-new',
  templateUrl: './withdrawal-create-new.component.html',
  styleUrls: ['./withdrawal-create-new.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class WithdrawalCreateNewComponent implements OnInit {
  entity: FormGroup
  responseMessage: Message[] = []
  showMessage = false
  isLoading = false
  showReceipt = false
  accounts :{label: string; value: string}[]= [{label: 'Selecciona una cuenta', value: ''}]
  withdrawalReceipt!: IWithdrawal
  constructor(
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    public userService: UserService,
    private accountService: AccountService,
    private withdrawalService: ApiWithdrawalService,
    private messageService: MessageService,
    private loaderService: LoaderService
  ) {
    if (this.userService.data === null) this.userService.goHome()

    this.entity = this.fb.group({
      amount: [0, [Validators.required, Validators.min(1), Validators.max(this.userService.data?.sodProperties.amountByWithdrawal ?? 0)]],
      accountId: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.getAccount()
  }

  getAccount () {
    this.accountService.getAll().subscribe(r => {
      const accounts = [{label: 'Selecciona una cuenta', value: ''}]
      if (!r.error) {
        r.data.forEach(a => {
          accounts.push({label: `${a.bank} **** ${a.lastFour}`, value: a._id})
        })
        this.accounts = accounts
      }
    })
  }

  confirm() {
    console.log("confirming dos")
    this.confirmationService.confirm({
      header: 'Confirmar operación',
      message: 'Seguro que deseas realizar el retiro',
      acceptLabel: 'Si, confirmar',
      rejectLabel: 'No, cancelar',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-sm',
      acceptButtonStyleClass: 'p-button-outlined p-button-sm',
      accept: () => {
        this.createWithdrawal()
      },
      reject: () => {

      }
    });
  }

  createWithdrawal() {
    this.isLoading = true
    this.loaderService.showSpinner()
    this.responseMessage = []
    this.withdrawalService.create(this.entity.value).subscribe(r => {
      this.loaderService.hideSpinner()
      if (r.error) {
        this.responseMessage = [
          {
            summary: r.msg,
            severity: 'error',
            closable: false,
          }
        ]
        this.isLoading = false
        setTimeout(() => {
          this.responseMessage = []
          this.showMessage = false
        }, 10000)
      } else {
        this.withdrawalReceipt = r.data
        this.userService.getData()
        setTimeout(() => {
          this.showReceipt = true
        }, 2000)

      }
    })
  }
}
