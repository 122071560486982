import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";
import {IWithdrawalResume} from "../../../interfaces/iwithdrawal.interface";
import {ILoan} from "../../../interfaces/iloan.interface";
import {environment} from "../../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LoanService extends ApiClass {
  constructor() {
    super()
    this.path = 'loan'
  }

  getAll() {
    return this.getCollection().pipe(map((r) => r as {error: boolean; msg: string; data: IWithdrawalResume[]}))
  }

  getById(id: string) {
    return this.getCollection(id).pipe(map((r) => r as {error: boolean; msg: string; data: ILoan}))
  }

  getBalance(id: string) {
    return this.getCollection(id, environment.apiUrl, 'loan/balance').pipe(map((r) => r as {error: boolean; msg: string; data: {url: string}}))
  }
}
