<ng-container>
  <form [formGroup]="entity">
    <div class="grid px-4">
      <div class="col-12">
        <p class="p mb-4">
          Asegurate de subir el documento correcto para que la validación sea mas ágil.
        </p>
      </div>
      <div class="col-12 mt-2 comment-rejected" *ngIf="data.comment && data.comment !== ''">
        Motivo de rechazo: <strong>{{data.comment}}</strong>
      </div>
      <div class="col-12">
        <div class="message">
          <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
        </div>
      </div>
      <div class="col-12">
        <div class="input-container">
          <div class="icon {{hasFile ? 'icon-filled' : 'icon-empty'}}">
            <fa-icon [icon]="faCircle"></fa-icon>
          </div>
          <div class="input-info">
            <h4>{{data.properties.title || 'Carga el documento solicitado'}}</h4>
            <p>{{data.properties.subtitle || ''}}</p>
          </div>
          <div class="action">
            <ng-container *ngIf="hasFile; else elseFile">
              <fa-icon [icon]="faTrashCan" (click)="file.clear();clear()"></fa-icon>
            </ng-container>
            <ng-template #elseFile>
              <fa-icon [icon]="faUpload"></fa-icon>
            </ng-template>
            <div class="fileUpload {{hasFile ? 'hide-all': ''}}">
              <p-fileUpload #file mode="basic" chooseLabel="Choose" name="file[]" accept="image/*,application/pdf" [maxFileSize]="1000000" (onSelect)="select($event)" [customUpload]="true" ></p-fileUpload>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 button-block-container my-5">
        <button pButton pRipple label="Guardar documento" icon="pi pi-check" class="p-button text-center justify-content-center button-block" (click)="save()" [disabled]="!hasFile"></button>
      </div>
    </div>
  </form>
</ng-container>
