export const MARRIAGE_STATUS_CONST = [
  {label: 'Selecciona una opción', value: ''},
  {label: 'Soltero(a)', value: 'SINGLE'},
  {label: 'Casado(a)', value: 'MARRIED'},
  {label: 'Divorciado(a)', value: 'DIVORCED'},
  {label: 'Viudo(a)', value: 'WIDOWER'},
  {label: 'Otro', value: 'OTHER'},
]

export const NATIONALITIES_CONST = [
  {label: 'Selecciona una opción', value: ''},
  {label: 'Mexicana', value: 'MEXICAN'},
  {label: 'Extranjera', value: 'FOREIGN'},
]

export const SECTORS_CONST = [
  {label: 'Selecciona una opción', value: ''},
  {label: 'Pensionados', value: 'RETIRED'},
  {label: 'IMSS', value: 'IMSS'}
]

export const DEPENDENCIES_CONST = [
  {label: 'Selecciona una opción', value: ''},
  {label: 'Ciudad de méxico', value: 'CDMX'},
  {label: 'Estado de México', value: 'GEM'}
]
