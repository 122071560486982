import {Component, OnInit} from '@angular/core';
import {faCircleUser, faFileInvoice, faTags} from "@fortawesome/free-solid-svg-icons";
import {ActivatedRoute} from "@angular/router";
import {LoanService} from "../../../../data/services/api/loan/loan.service";
import {MOVEMENT_ENUM, MOVEMENT_ENUM_COLOR} from "../../../../data/enum/movements.enum";
import {ILoan} from "../../../../data/interfaces/iloan.interface";
import {Message} from "primeng/api";
import {LoaderService} from "../../../../data/services/page/loader.service";
import * as CapacitorBrowser from "@capacitor/browser";

@Component({
  selector: 'app-loan-detail',
  templateUrl: './loan-detail.component.html',
  styleUrls: ['./loan-detail.component.scss']
})
export class LoanDetailComponent implements OnInit {
  id: string
  protected readonly faCircleUser = faCircleUser;
  protected readonly faTags = faTags;
  protected readonly faFileInvoice = faFileInvoice;
  $_movementEnum = MOVEMENT_ENUM
  $_movementEnumColor = MOVEMENT_ENUM_COLOR
  data!: ILoan
  showMessage = false
  responseMessage: Message[] = []
  constructor(
    private activeRoute: ActivatedRoute,
    private loanService: LoanService,
    private loaderService: LoaderService
  ) {
    this.id = this.activeRoute.snapshot.paramMap.get('id') as string
    console.log(this.id)
  }

  ngOnInit() {
    this.getData()
  }

  getData() {
    this.loanService.getById(this.id).subscribe(r => {
      console.log(r)
      if (!r.error) {
        this.data = r.data
        this.data.installments = this.data.installments.sort((a, b) => a.index - b.index)
      }
    })
  }

  get getAllInterests() {
    return this.data.installments.reduce((acc, i) => acc + i.revenueAmount, 0)
  }

  get getAdvanceInstallments () {
    const totalPaid = this.data.installments.filter(i => i.collectedAmount >= (i.revenueAmount + i.principalAmount - 0.1))
    return `${this.data.installmentCount } / ${totalPaid.length}`
  }

  get getTotalPaid() {
    return this.data.installments.reduce((acc, i) => acc + i.collectedAmount, 0)
  }

  get getPendingToPaid() {
    return this.data.installments.reduce((acc, i) => acc + ((i.collectedAmount >= (i.revenueAmount + i.principalAmount - 0.1)) ? 0 : (i.revenueAmount + i.principalAmount - i.collectedAmount)), 0)
  }

  async downloadBalance() {
    this.loaderService.showSpinner()
    this.loanService.getBalance(this.id).subscribe(r => {
      if (r.error) {

        this.responseMessage = [
          {
            summary: r.msg,
            severity: 'error',
            closable: false,
          }
        ]
        this.showMessage = true
        this.loaderService.hideSpinner()
        setTimeout(() => {
          this.responseMessage = []
          this.showMessage = false
        }, 10000)
      } else {
        setTimeout(async () => {
          this.responseMessage = []
          this.showMessage = false
          await CapacitorBrowser.Browser.open({url: `${r.data.url}`})
          this.loaderService.hideSpinner()
        }, 3000)
      }
    })
  }
}
