import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";
import {IFlowByStages} from "../../../interfaces/iflow.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiFlowService extends ApiClass {

  constructor() {
    super()
    this.path = 'flow'
  }

  getFlow() {
    return this.postCollection({}).pipe(map((r) => r as {error: boolean; msg: string; data: IFlowByStages}))
  }

  getStatus() {
    return this.postCollection({}, 'status').pipe(map((r) => r as {error: boolean, msg: string; data: {percentCompleted: number}}))
  }
}
