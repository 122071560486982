import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";
import {IAccount} from "../../../interfaces/iaccount.interface";

@Injectable({
  providedIn: 'root'
})
export class AccountService extends ApiClass {

  constructor() {
    super()
    this.path = 'account'
  }

  getAll() {
    return this.getCollection().pipe(map((r) => r as {error: boolean; msg: string; data: IAccount[] }))
  }

  delete(id: string) {
    return this.deleteCollection(id).pipe(map((r) => r as {error: boolean; msg: string;}))
  }

  create(data: any) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string;data: IAccount}))
  }
}
