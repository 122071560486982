<div class="info-card">
  <div class="info-card_header">
    <fa-icon [icon]="faCircleUser"></fa-icon>
    <h3>¡Hola, {{(authService.getUser?.user?.names?.firstName || 'colaborador') | titlecase}}!</h3>
  </div>
  <div class="info-card_content">
    <h1>{{title}}</h1>
    <h2>
      <span class="{{subtitleLeft !== '' ? 'mr-2': ''}}">{{subtitleLeft}}</span>
      <fa-icon [icon]="icon"></fa-icon>
      <span class="{{subtitleRight !== '' ? 'ml-2': ''}}">{{subtitleRight}}</span>
    </h2>
  </div>
</div>
