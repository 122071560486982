import { Component } from '@angular/core';
import {
  faAngleRight,
  faCircleQuestion, faCircleUser,
  faRightFromBracket,
  faScaleBalanced,
  faSliders
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-skeleton-left-menu',
  templateUrl: './skeleton-left-menu.component.html',
  styleUrls: ['./skeleton-left-menu.component.scss']
})
export class SkeletonLeftMenuComponent {
  nextActionIcon = faAngleRight
  actions: {icon: any; title: string; route: string; }[] = [
    {icon: faCircleUser, title: 'Configurar datos personales', route: ''},
    {icon: faSliders, title: 'Configuración de la aplicación', route: ''},
    {icon: faCircleQuestion, title: 'Ayuda', route: ''},
    {icon: faScaleBalanced, title: 'Legales', route: ''},
    {icon: faRightFromBracket, title: 'Cerrar Sesión', route: ''},
  ]
}
