import {Component, Input} from '@angular/core';
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import * as CapacitorBrowser from '@capacitor/browser';

@Component({
  selector: 'app-benefit-card',
  templateUrl: './benefit-card.component.html',
  styleUrls: ['./benefit-card.component.scss']
})
export class BenefitCardComponent {
  @Input() title = ''
  @Input() actionLabel = ''
  @Input() image = ''
  @Input() background = ''
  @Input() url = ''
  protected readonly faAngleRight = faAngleRight;

  async navigate(url: string) {
    await CapacitorBrowser.Browser.open({url: `${url}`})
  }
}
