<form [formGroup]="entity">
  <div class="row p-4">
    <div class="col-12 text-left mb-5">
      <p>Asegurate de tener acceso a cada medio de contacto</p>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <label for="phoneNumber">Teléfono</label>
        <p-inputMask mask="+999999999999" id="phoneNumber" formControlName="phoneNumber" placeholder="+52 55 3424 3312"></p-inputMask>
        <ng-container *ngIf="entity.get('phoneNumber')?.invalid && (entity.get('phoneNumber')?.dirty || entity.get('phoneNumber')?.touched)">
          <small class="p-error" *ngIf="entity.get('phoneNumber')?.errors?.['required']">El campo es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('phoneNumber')?.errors?.['pattern']">El teléfono no cumple con el formato +52 55 4323 4233.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 md:col-6">
      <div class="field field-flex mb-3">
        <label for="emailAddress">Email:</label>
        <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="correo@gmail.com" required />
        <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
          <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
          <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-5">
        <label for="password">Contraseña</label>
        <p-password [feedback]="true" placeholder="************" formControlName="password" [toggleMask]="true"></p-password>
        <ng-container *ngIf="entity.get('password')?.invalid && (entity.get('password')?.dirty || entity.get('password')?.touched)">
          <small class="p-error" *ngIf="entity.get('password')?.errors?.['required']">La contraseña es obligatoria.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 flex flex-column">
      <button pButton pRipple label="Continuar" class="p-button-primary button-block mb-3" (click)="sendData()" [disabled]="entity.invalid"></button>
      <button pButton pRipple label="Contáctanos por Whatsapp" class="p-button-primary p-button-outlined button-block mb-3" (click)="whatsapp()"></button>
    </div>
  </div>
</form>
