import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  spinnerShow = false

  hideSpinner() {
    setTimeout(() => {
      this.spinnerShow = false
    }, 3000)
  }

  showSpinner() {
      this.spinnerShow = true
  }
}
