import {Component, Input} from '@angular/core';
import {IWorkerProperties} from "../../interfaces/worker.interface";

@Component({
  selector: 'app-home-current-flow',
  templateUrl: './home-current-flow.component.html',
  styleUrls: ['./home-current-flow.component.scss']
})
export class HomeCurrentFlowComponent {
  @Input() data!: IWorkerProperties | null
}
