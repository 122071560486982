import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {IOnboarding} from "../../../../../data/interfaces/ionboarding.interface";

@Component({
  selector: 'app-onboarding-chose-product',
  templateUrl: './onboarding-chose-product.component.html',
  styleUrls: ['./onboarding-chose-product.component.scss']
})
export class OnboardingChoseProductComponent {
  @Input() entity!: FormGroup
  @Input() mainPoints: {label: string; value: string}[] = []
  @Output() onSendData = new EventEmitter()
  @Input() data!: IOnboarding

  sendData() {
    this.onSendData.emit()
  }

  get getCorrectProduct () {
    let mainPoints = null
    if (this.data) mainPoints = this.data.products.find (p => p.id === this. entity.value.desireProduct)
    return mainPoints ? mainPoints.mainPoints :[]
  }

  get getCorrectTitle() {
    let title = '¿Qué producto te interesa?'
    let label = 'Producto:*'
    if (this.data.products.filter((p) => p.id.includes('MAS_NOMINA')).length > 0) {
      title = '¿Cuál es tu centro de trabajo?'
      label = 'Centro de trabajo:*'
    }
    return {label, title}
  }

  whatsapp() {
    window.open('https://wa.me/525580284233?text=Hola+me+interesa','_blank')
  }

  protected readonly faCheckCircle = faCheckCircle;
}
