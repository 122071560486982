import { Component } from '@angular/core';
import {faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../../data/services/modules/auth/auth.service";
import {UserService} from "../../../../data/services/page/user.service";

@Component({
  selector: 'app-profile-user',
  templateUrl: './profile-user.component.html',
  styleUrls: ['./profile-user.component.scss']
})
export class ProfileUserComponent {

  protected readonly faUser = faUser;
  constructor(
    public authService: AuthService,
    private userService: UserService
  ) {
  }

  closeSession() {
    this.authService.logout()
    this.userService.subjectUser.next(null)
  }

  protected readonly faLock = faLock;
}
