import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components'
import * as fromPipes from './pipes'
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {InputTextModule} from "primeng/inputtext";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PasswordModule} from "primeng/password";
import {InputMaskModule} from "primeng/inputmask";
import {ProgressBarModule} from "primeng/progressbar";
import {InputNumberModule} from "primeng/inputnumber";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TagModule} from "primeng/tag";
import {AppRoutingModule} from "../app-routing.module";
import {KnobModule} from "primeng/knob";
import {MessagesModule} from "primeng/messages";
import {SkeletonModule} from "primeng/skeleton";
import {CardModule} from "primeng/card";
import {CalendarModule} from "primeng/calendar";
import {FileUploadModule} from "primeng/fileupload";
import {RadioButtonModule} from "primeng/radiobutton";


@NgModule({
  declarations: [
    ...fromComponents.components,
    ...fromPipes.pipes
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        InputTextModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        PasswordModule,
        InputMaskModule,
        ProgressBarModule,
        InputNumberModule,
        ConfirmDialogModule,
        MessagesModule,
        SkeletonModule,
        CalendarModule,
        FileUploadModule,
        RadioButtonModule,
        TagModule,
        KnobModule,
        AppRoutingModule,
        CardModule
    ],
  exports: [
    InputTextModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PasswordModule,
    InputMaskModule,
    ProgressBarModule,
    InputNumberModule,
    ConfirmDialogModule,
    FileUploadModule,
    MessagesModule,
    SkeletonModule,
    CalendarModule,
    RadioButtonModule,
    TagModule,
    KnobModule,
    ...fromComponents.components,
    ...fromPipes.pipes
  ]
})
export class SharedModule { }
