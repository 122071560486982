import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Message} from "primeng/api";
import {IFlow} from "../../../../../data/interfaces/iflow.interface";
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {LoaderService} from "../../../../../data/services/page/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CurrencyPipe} from "@angular/common";
import {SelectEnumPipe} from "../../../../../shared/pipes";
import {PERIODS_TYPE_ENUM_ES} from "../../../../../data/enum/period.enum";

@Component({
  selector: 'app-offer-component',
  templateUrl: './offer-component.component.html',
  styleUrls: ['./offer-component.component.scss'],
  providers: [CurrencyPipe, SelectEnumPipe]
})
export class OfferComponentComponent implements OnInit{
  responseMessage: Message[] = []
  showMessage = false
  entity!: FormGroup
  min = 0
  max = 0
  offers: {key: number; name: string;}[] = []
  period =  ''
  $_periodsEnum = PERIODS_TYPE_ENUM_ES
  @Input() data!: IFlow
  @Output() onEndProcess = new EventEmitter()
  @Output() onUpdateStage = new EventEmitter()
  constructor(
    private flowDataService: ApiFlowDataService,
    private loaderService: LoaderService,
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private selectedEnumPipe: SelectEnumPipe
  ) {
  }

  ngOnInit() {
    this.getOffers()
  }

  getOffers() {
    this.loaderService.showSpinner()
    this.flowDataService.getOffers({idDocument: this.data.id, idStage: this.data.userDocumentId}).subscribe(r => {
      this.loaderService.hideSpinner()
      if (!r.error) {
        this.data.userDocumentId = r.data.stageId
        this.entity = this.fb.group({
          amount: [0, [Validators.required, Validators.min(r.data.loanProperties.minAmount), Validators.max(r.data.loanProperties.loanAmountAllowed)]],
          installmentCount: ['', [Validators.required]]
        })
        this.min = r.data.loanProperties.minAmount
        this.max = r.data.loanProperties.loanAmountAllowed
        this.period = r.data.loanProperties.period
      }
    })
  }

  clearOffers() {
    this.offers = []
  }
  calculateOffers() {
    this.offers = []
    this.loaderService.showSpinner()
    this.flowDataService.calculateOffers({idDocument: this.data.id, idStage: this.data.userDocumentId, ...this.entity.value}).subscribe(r => {
      this.loaderService.hideSpinner()
      if (!r.error) {
        this.offers = r.data.offers.map(o => {
          return {
            key: o.installmentCount,
            name: `${this.currencyPipe.transform(o.installmentAmount, 'MXN')} - ${o.installmentCount} ${this.selectedEnumPipe.transform(this.period, this.$_periodsEnum)}`
          }
        })
      } else {
        this.responseMessage = [
          {
            summary: r.msg,
            severity: r.error ?  'error': 'success',
            closable: false,
          }
        ]
      }
    })
  }

  understand() {
    this.onEndProcess.emit(true)
  }

  applyForOffer() {
    console.log(this.entity.value)
    this.loaderService.showSpinner()
    this.flowDataService.applyOffers({idDocument: this.data.id, idStage: this.data.userDocumentId, amount: this.entity.value.amount, installmentCount: this.entity.value.installmentCount.key}).subscribe(r => {
      this.loaderService.hideSpinner()
      if (!r.error) {
        this.onEndProcess.emit(true)
      } else {
        this.responseMessage = [
          {
            summary: r.msg,
            severity: r.error ?  'error': 'success',
            closable: false,
          }
        ]
      }
    })
  }
}
