import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IFlow} from "../../../../../data/interfaces/iflow.interface";
import * as CapacitorBrowser from "@capacitor/browser";
import * as CapacitorCore from '@capacitor/core';
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {LoaderService} from "../../../../../data/services/page/loader.service";
import {Message} from "primeng/api";
@Component({
  selector: 'app-ine-validation',
  templateUrl: './ine-validation.component.html',
  styleUrls: ['./ine-validation.component.scss']
})
export class IneValidationComponent implements OnInit {
  responseMessage: Message[] = []
  showMessage = false
  startButtonIsAvailable = false
  @Input() data!: IFlow
  @Output() onEndProcess = new EventEmitter()
  @Output() onUpdateStage = new EventEmitter()
  currentUrl = ''
  startedProcess = false
  constructor(
    private flowDataService: ApiFlowDataService,
    private loaderService: LoaderService
  ) {
  }
  async ngOnInit() {
    await this.getUrl()
  }

  getUrl() {
    this.loaderService.showSpinner()
    this.flowDataService.getIneValidationUrl({idDocument: this.data.id, idStage: this.data.userDocumentId}).subscribe(r => {
      this.loaderService.hideSpinner()
      console.log(r.data)
      if (!r.error) {
        this.data.userDocumentId = r.data.stageId
        if (r.data.sessionStatus === 'VALIDATED') this.onEndProcess.emit(true)
        else this.onUpdateStage.emit()
        this.currentUrl = r.data.url
        this.startButtonIsAvailable = true
      } else {
        this.responseMessage = [
          {
            summary: r.msg,
            severity: r.error ?  'error': 'success',
            closable: false,
          }
        ]
      }
    })
  }

  async openIneValidationUrl(url: string) {
    if (CapacitorCore.Capacitor.getPlatform() === 'web') {
      const incodeWindow = open(
        url,
        'user-validation',
        'width=550,height=700,status=0,toolbar=0'
      );
    } else {
      await CapacitorBrowser.Browser.open({url})
      /*await CapacitorBrowser.Browser.close().then(r => {
        console.log("url closed")
      })*/
    }
  }

  async start() {
    await this.openIneValidationUrl(this.currentUrl)
    setTimeout(( ) => {
      this.startedProcess = true
    }, 2000)

  }
  validateUserIdentity() {
    console.log("validating")
    this.loaderService.showSpinner()
    setTimeout(() => {
      this.flowDataService.validateIneValidationStatus({idDocument: this.data.id, idStage: this.data.userDocumentId}).subscribe(r => {
        this.loaderService.hideSpinner()
        this.responseMessage = [
          {
            summary: r.msg,
            severity: r.error ?  'error': 'success',
            closable: false,
          }
        ]
        if (!r.error) {
          setTimeout(() => {
            this.onEndProcess.emit(true)
          }, 2000)
       } else this.onUpdateStage.emit()
      })
    }, 5000)

  }
}
