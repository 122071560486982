import {Component, Input} from '@angular/core';
import {faCircleUser} from "@fortawesome/free-solid-svg-icons";
import {IWorkerProperties} from "../../interfaces/worker.interface";

@Component({
  selector: 'app-home-main-section',
  templateUrl: './home-main-section.component.html',
  styleUrls: ['./home-main-section.component.scss']
})
export class HomeMainSectionComponent {
  today = new Date()
  @Input() data!: IWorkerProperties | null
  protected readonly faCircleUser = faCircleUser;
}
