<div class="container-screen">
  <app-app-navigation-header
    [title]="titleSection"
    [backRoute]="backRoute"
    (onClick)="showVerificationScreen($event)"
  ></app-app-navigation-header>
  <ng-container *ngIf="screenVerificationList; else elseVerificationList">
    <app-progress-verification [data]="data"></app-progress-verification>
    <app-verification-documents-list [data]="data" (onClick)="showScreen($event)"></app-verification-documents-list>
  </ng-container>
  <ng-template #elseVerificationList>
    <ng-container [ngSwitch]="currentScreenType">
      <app-form-component *ngSwitchCase="'FORM'" [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)"></app-form-component>
      <app-document-component *ngSwitchCase="'DOCUMENT'" [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)"></app-document-component>
      <app-ine-validation *ngSwitchCase="'INCODE'" [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)" (onUpdateStage)="retryFlow()"></app-ine-validation>
      <app-sign-document *ngSwitchCase="'WEETRUST'" [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)"></app-sign-document>
      <app-offer-component *ngSwitchCase="'OFFER'" [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)"></app-offer-component>
      <app-manual-validation-component *ngSwitchCase="'MANUAL'" [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)"></app-manual-validation-component>
      <app-external-component *ngSwitchDefault [data]="currentScreenData" (onEndProcess)="showVerificationScreen($event)"></app-external-component>
    </ng-container>
  </ng-template>
</div>
