import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Message} from "primeng/api";
import {AuthService} from "../../../../data/services/modules/auth/auth.service";
import {LoaderService} from "../../../../data/services/page/loader.service";

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent {
  entity: FormGroup
  showMessage = false
  responseMessage : Message[] = []
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private loadService: LoaderService
  ) {
    this.entity = this.fb.group({
      emailAddress: [ '', [Validators.required,Validators.pattern(/^[a-zA-Z0-9_.+]*[a-zA-Z][a-zA-Z0-9_.+]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)]]
    })
  }

  resetPassword() {
    this.showMessage = false
    this.loadService.showSpinner()
    this.authService.resetPassword(this.entity.value).subscribe(r => {
      this.loadService.hideSpinner()
      this.showMessage = true
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ?  'error': 'success',
          closable: true
        }
      ]
    })
  }

  whatsapp() {
    window.open('https://wa.me/525580284233?text=Hola+necesito+ayuda','_blank')
  }
}
