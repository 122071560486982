<div class="left-menu">
  <app-app-navigation-header title="Perfil"></app-app-navigation-header>
  <div class="actions-container">
    <ng-container *ngFor="let action of actions">
      <div class="action">
        <div class="action_title">
          <fa-icon [icon]="action.icon"></fa-icon>
          <h3>{{action.title}}</h3>
        </div>
        <fa-icon [icon]="nextActionIcon" ></fa-icon>
      </div>
    </ng-container>
  </div>
</div>
