import {Component, Input, OnInit} from '@angular/core';
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {ApiFlowService} from "../../../../../data/services/api/flow/api-flow.service";
import {IFlowByStages} from "../../../../../data/interfaces/iflow.interface";

@Component({
  selector: 'app-progress-verification',
  templateUrl: './progress-verification.component.html',
  styleUrls: ['./progress-verification.component.scss']
})
export class ProgressVerificationComponent implements OnInit {
  @Input() data!: IFlowByStages
  progress = 0

  constructor(
    private flowService: ApiFlowService
  ) {
  }

  ngOnInit() {
    this.getStatus()
  }

  getStatus() {
    this.flowService.getStatus().subscribe(r => {
      console.log(r)
      if (!r.error) this.progress = Math.floor(r.data.percentCompleted)
    })
  }
}
