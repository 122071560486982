import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-information-external-link',
  templateUrl: './verify-information-external-link.component.html',
  styleUrls: ['./verify-information-external-link.component.scss']
})
export class VerifyInformationExternalLinkComponent {

}
