import {Component, Input} from '@angular/core';
import {IWorkerProperties} from "../../interfaces/worker.interface";

@Component({
  selector: 'app-card-default',
  templateUrl: './card-default.component.html',
  styleUrls: ['./card-default.component.scss']
})
export class CardDefaultComponent {
  @Input() data!: IWorkerProperties | null
}
