import { Component } from '@angular/core';

@Component({
  selector: 'app-verify-information-detail-step',
  templateUrl: './verify-information-detail-step.component.html',
  styleUrls: ['./verify-information-detail-step.component.scss']
})
export class VerifyInformationDetailStepComponent {

}
