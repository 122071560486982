import {SelectEnumPipe} from "./select-enum.pipe";
import {IsoDateEnumPipe} from './enum/iso-date-enum.pipe'

export const pipes: any[] = [
  IsoDateEnumPipe,
  SelectEnumPipe
]

export * from './enum/iso-date-enum.pipe';
export * from "./select-enum.pipe";
