import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";
import {IFlow} from "../../../interfaces/iflow.interface";
import {IWeetrustDocument} from "../../../interfaces/iweetrusth.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiFlowDataService extends ApiClass {

  constructor() {
    super()
    this.path = 'flowData'
  }

  saveInfo(data: any) {
    return this.postCollection(data, 'form').pipe(map((r) => r as {error: boolean, msg: string; data: IFlow}))
  }

  uploadDocument(data: any) {
    return this.postCollection(data, 'document').pipe(map((r) => r as {error: boolean, msg: string; data: IFlow}))
  }

  getIneValidationUrl(data: any) {
    return this.postCollection(data, 'ineValidationUrl').pipe(map((r) => r as {error: boolean, msg: string; data: {url: string;sessionStatus: string, stageId: string}}))
  }

  validateIneValidationStatus(data: any) {
    return this.postCollection(data, 'ineValidationStatus').pipe(map((r) => r as {error: boolean, msg: string; data: IFlow}))
  }

  validateExternalUrl(data: any) {
    return this.postCollection(data, 'external').pipe(map((r) => r as {error: boolean, msg: string; data: IFlow}))
  }

  getWeetrustDocument(data: any) {
    return this.postCollection(data, 'documentSign').pipe(map((r) => r as {error: boolean, msg: string; data: {
      weetrustDocument: IWeetrustDocument,
      status: string;
      stageId: string;
    }}))
  }

  getOffers(data: any) {
    return this.postCollection(data, 'getOffers').pipe(map((r) => r as {
      error: boolean; msg: string; data: {stageId: string; loanProperties: {
          loanStatusEnabled: boolean;
          loanAmountAllowed: number;
          loanRate: number;
          period: string;
          minAmount: number;
        }}
    }))
  }

  calculateOffers(data: any) {
    return this.postCollection(data, 'calculateOffers').pipe(map((r) => r as {
      error: boolean; msg: string; data: {stageId: string; offers: {installmentCount: number; installmentAmount: number}[]}
    }))
  }

  applyOffers(data: any) {
    return this.postCollection(data, 'applyOffer').pipe(map((r) => r as {
      error: boolean; msg: string; data: {stageId: string;}
    }))
  }
}
