import {Component, Input} from '@angular/core';
import {faBriefcase, faCalendarDays, faCircle} from "@fortawesome/free-solid-svg-icons";
import {IWorkerProperties} from "../../interfaces/worker.interface";

@Component({
  selector: 'app-card-sod',
  templateUrl: './card-sod.component.html',
  styleUrls: ['./card-sod.component.scss']
})
export class CardSodComponent {
    @Input() data!: IWorkerProperties | null
    protected readonly faCalendarDays = faCalendarDays;
    protected readonly faBriefcase = faBriefcase;
    protected readonly faCircle = faCircle;

    get getPercents() {
      const maxToAchieve = this.data?.sodProperties?.accruedSalary ?? 1
      const available = this.data?.sodProperties?.availableAppSalary ?? 0
      const withdrawn = this.data?.sodProperties?.amountWithdrawn ?? 0
      return {available: (available / maxToAchieve) * 100, withdrawn: (withdrawn / maxToAchieve) * 100}
    }
}
