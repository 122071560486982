import { Component } from '@angular/core';
import {faFileInvoice, faScaleBalanced} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-balance-state',
  templateUrl: './balance-state.component.html',
  styleUrls: ['./balance-state.component.scss']
})
export class BalanceStateComponent {

    protected readonly faFileInvoice = faFileInvoice;
  protected readonly faScaleBalanced = faScaleBalanced;
}
