import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isoDateEnum',
})
export class IsoDateEnumPipe implements PipeTransform {
  transform(value: any, defaultOption = '--'): any {
    if (value === null || value === undefined || value === '') return defaultOption
    return value.substring(0,10)
  }
}
