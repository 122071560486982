import {Component, Input} from '@angular/core';
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {AuthService} from "../../../../../data/services/modules/auth/auth.service";
import {IWithdrawal} from "../../../../../data/interfaces/iwithdrawal.interface";

@Component({
  selector: 'app-withdrawal-receipt',
  templateUrl: './withdrawal-receipt.component.html',
  styleUrls: ['./withdrawal-receipt.component.scss']
})
export class WithdrawalReceiptComponent {
  today = new Date()
  @Input() withdrawal!: IWithdrawal
  protected readonly faCheckCircle = faCheckCircle;
  constructor(
    public authService: AuthService
  ) {
  }
}
