<form [formGroup]="entity">
  <div class="row p-3">
    <div class="col-12 text-center mb-5">
      <p>Queremos conocerte un poco más</p>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-5">
        <label for="sector">Sector al que perteneces:*</label>
        <p-dropdown formControlName="sector" [panelStyle]="{'width': '100%' }" [options]="$_sectors" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        <ng-container *ngIf="entity.get('sector')?.invalid && (entity.get('sector')?.dirty || entity.get('sector')?.touched)">
          <small class="p-error" *ngIf="entity.get('sector')?.errors?.['required']">El estado civil es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-5">
        <label for="dependency">Dependencia:*</label>
        <p-dropdown formControlName="dependency" [panelStyle]="{'width': '100%' }" [options]="$_dependencies" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        <ng-container *ngIf="entity.get('dependency')?.invalid && (entity.get('dependency')?.dirty || entity.get('dependency')?.touched)">
          <small class="p-error" *ngIf="entity.get('dependency')?.errors?.['required']">El estado civil es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 flex flex-column">
      <button pButton pRipple label="Continuar" class="p-button-primary button-block mb-3" (click)="sendData()" [disabled]="entity.invalid"></button>
      <button pButton pRipple label="Contáctanos por Whatsapp" class="p-button-primary p-button-outlined button-block mb-3" (click)="whatsapp()"></button>
    </div>
  </div>
</form>
