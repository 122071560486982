<div class="row py-2 px-4">
  <div class="container-card">
    <div class="container-progress">
      <div class="container-progress_circle">
        <svg
          width="150" height="150" viewBox="0 0 150 150"
          class="circular-progress" style="--progress: {{progress}}"
        >
          <circle class="bg"></circle>
          <circle class="fg"></circle>
        </svg>
      </div>
      <div class="container-progress_advance">
        <h4>{{progress}}%</h4>
        <p>Completado</p>
      </div>
    </div>
    <p class="text-center">Completa tu solicitud de crédito</p>
  </div>
</div>
