import {Component, Input, OnInit} from '@angular/core';
import {faBank, faCreditCard, faMoneyBillTransfer, faPhone, faReceipt} from "@fortawesome/free-solid-svg-icons";
import {IWorkerProperties} from "../../interfaces/worker.interface";

@Component({
  selector: 'app-home-actions',
  templateUrl: './home-actions.component.html',
  styleUrls: ['./home-actions.component.scss']
})
export class HomeActionsComponent implements OnInit {
  @Input() data!: IWorkerProperties | null
  activities: {title: string; icon: any; route?: string; enabled: boolean}[] = []
  ngOnInit() {
    console.log(this.data)
    this.activities = [
      {title: 'Retirar dinero', icon: faMoneyBillTransfer, route: '/withdrawal',  enabled: this.data?.permissions?.withdrawals ?? false},
      {title: 'Solicitar crédito', icon: faCreditCard, route: '/loan', enabled: this.data?.permissions?.loans ?? false},
      {title: 'Estado de cuenta', icon: faReceipt, route: '/balance', enabled: this.data?.permissions?.balance ?? false},
      {title: 'Recargar celular', icon: faPhone, route: '', enabled: this.data?.permissions?.phoneServices ?? false},
      {title: 'Pagar servicios', icon: faBank, route: '', enabled: this.data?.permissions?.globalServices ?? false},
    ]
  }
}
