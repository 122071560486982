<form [formGroup]="entity">
  <div class="row p-3">
    <div class="col-12 text-center mb-5">
      <p>{{getCorrectTitle.title}}</p>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-2">
        <label for="desireProduct">{{getCorrectTitle.label}}</label>
        <p-dropdown formControlName="desireProduct" [panelStyle]="{'width': '100%' }" [options]="mainPoints" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
        <ng-container *ngIf="entity.get('desireProduct')?.invalid && (entity.get('desireProduct')?.dirty || entity.get('desireProduct')?.touched)">
          <small class="p-error" *ngIf="entity.get('desireProduct')?.errors?.['required']">El campo es obligatorio.</small>
        </ng-container>
      </div>
    </div>
    <div class="col-12 mb-5">
      <ng-container *ngFor="let mainPoint of getCorrectProduct">
       <div class="main-point">
         <fa-icon [icon]="faCheckCircle"></fa-icon>
         <span>{{mainPoint}}</span>
       </div>
      </ng-container>
    </div>
    <div class="col-12 flex flex-column">
      <button pButton pRipple label="Continuar" class="p-button-primary button-block mb-3" (click)="sendData()" [disabled]="entity.invalid"></button>
      <button pButton pRipple label="Contáctanos por Whatsapp" class="p-button-primary p-button-outlined button-block mb-3" (click)="whatsapp()"></button>
    </div>
  </div>
</form>
