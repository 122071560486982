import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PushingService extends ApiClass {

  constructor() {
    super()
    this.path = 'pushing'
  }

  create(data: any) {
    return this.postCollection(data, 'create').pipe(map((r) => r as {error: boolean; msg: string; data: boolean}))
  }
}
