import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DEPENDENCIES_CONST, SECTORS_CONST} from "../../data/constants/onboarding.const";

@Component({
  selector: 'app-onboarding-choose-sector',
  templateUrl: './onboarding-choose-sector.component.html',
  styleUrls: ['./onboarding-choose-sector.component.scss']
})
export class OnboardingChooseSectorComponent {
  $_sectors = SECTORS_CONST
  $_dependencies = DEPENDENCIES_CONST
  @Output() onSendData = new EventEmitter()
  @Input() entity!: FormGroup


  sendData() {
    this.onSendData.emit()
  }

  whatsapp() {
    window.open('https://wa.me/525580284233?text=Hola+necesito+ayuda','_blank')
  }
}
