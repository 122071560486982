import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {IWorkerProperties} from "../../../modules/private/home/interfaces/worker.interface";
import {WorkerService} from "../api/worker.service";
import {AuthService} from "../modules/auth/auth.service";
import {Router} from "@angular/router";
import {LoaderService} from "./loader.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  subjectUser: BehaviorSubject<IWorkerProperties | null>
  currentUser: Observable<IWorkerProperties | null>
  constructor(
    private workerService: WorkerService,
    private authService: AuthService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.subjectUser = new BehaviorSubject<IWorkerProperties | null>(null)
    this.currentUser = this.subjectUser.asObservable()
    this.getData()
  }

  goHome() {
    this.router.navigate(['/'])
  }
  getData() {
    if(this.data === null) this.loaderService.showSpinner()
    this.workerService.getUserProperties().subscribe(r => {
      this.loaderService.hideSpinner()
      if (r.error) {
        this.authService.logout()
      } else this.set(r.data)
    })
  }

  set(user: IWorkerProperties) {
    this.subjectUser.next(user)
  }

  get data(): IWorkerProperties | null {
    return this.subjectUser.value
  }
}
