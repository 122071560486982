<div class="info-card">
  <div class="info-card_header">
    <fa-icon [icon]="faCircleUser"></fa-icon>
    <h3>Hola, {{data?.user?.firstName ?? '-' | titlecase}}</h3>
  </div>
  <div class="info-card_content">
    <ng-container *ngIf="data && data.sodProperties.sodEnabled; else elseSod">
      <h1>{{data.sodProperties.availableAppSalary | currency:'MXN'}}</h1>
      <p>Salario disponible</p>
    </ng-container>
    <ng-template #elseSod>
      <h1>Bienvenido</h1>
      <p>{{ today | date: 'dd MMMM YYYY':'es-MX' }}</p>
    </ng-template>
  </div>
</div>
