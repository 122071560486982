<div class="balance-amounts">
  <div class="balance-amounts_main">
    <h5>Saldo pendiente al corte</h5>
    <h1>$3,000.00</h1>
  </div>
  <div class="balance-amounts_item">
    <p>Próxima fecha de corte:</p>
    <p>22 dic 2023</p>
  </div>
  <h5 class="mb-2">Créditos</h5>
  <div class="balance-amounts_item">
    <p>Créditos totales:</p>
    <p>3</p>
  </div>
  <div class="balance-amounts_item">
    <p>Total pendiente de créditos:</p>
    <p>$4,000.00</p>
  </div>
  <h5 class="mb-2">Retiros</h5>
  <div class="balance-amounts_item">
    <p>Total de retiros:</p>
    <p>100</p>
  </div>
  <div class="balance-amounts_item">
    <p>Total pendiente de retiros:</p>
    <p>$4,000.00</p>
  </div>
  <h5 class="mb-2">Totales</h5>
  <div class="balance-amounts_item">
    <p>Saldo total pendiente:</p>
    <p>$8,000.00</p>
  </div>
</div>
