<app-app-navigation-header
  [title]="'Retiro de dinero'"
  [closeRoute]="'/'"
></app-app-navigation-header>
<div class="withdrawal">
  <div class="withdrawal-folio">
    <fa-icon [icon]="faCheckCircle"></fa-icon>
    <h3>{{authService.getUser?.user?.names?.firstName ?? ''}}, tu retiro fue exitoso</h3>
    <p>Folio: {{withdrawal.folio | uppercase}}</p>
    <p>{{today | date: 'dd MMMM YYYY hh:mm:ss'}}</p>
  </div>
 <div class="withdrawal-resume">
   <div class="withdrawal-resume_item">
     <p>Retiro</p>
     <p>{{withdrawal.amount | currency: 'MXN'}}</p>
   </div>
   <div class="withdrawal-resume_item">
     <p>Comisión por retiro</p>
     <p>{{withdrawal.fee | currency: 'MXN'}}</p>
   </div>
   <div class="withdrawal-resume_item">
     <p>Total</p>
     <p>{{ (withdrawal.fee + withdrawal.amount) | currency: 'MXN'}}</p>
   </div>
 </div>
  <div class="withdrawal-footer">
    <p>Enviamos los detalles de tu retiro al correo</p>
    <p><strong>{{authService.getUser?.user?.emailAddress ?? ''}}</strong></p>
  </div>
</div>
