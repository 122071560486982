<div class="actions">
  <h3>Actividades</h3>
  <div class="actions-container">
    <ng-container *ngFor="let activity of activities">
      <div class="actions_action {{!activity.enabled ? 'actions_action-disabled': 'actions_action-enabled'}}">
        <ng-container *ngIf="activity.enabled;else elseWithdrawal">
          <div class="actions_action-icon" [routerLink]="activity.route">
            <fa-icon [icon]="activity.icon"></fa-icon>
          </div>
        </ng-container>
        <ng-template #elseWithdrawal>
          <div class="actions_action-icon">
            <fa-icon [icon]="activity.icon"></fa-icon>
          </div>
        </ng-template>
        <h5>{{activity.title}}</h5>
      </div>
    </ng-container>
  </div>
</div>
