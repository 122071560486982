import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnboardingProcessComponent } from './modules/public/onboarding/onboarding-process/onboarding-process.component';
import { OnboardingGeneralInformationComponent } from './modules/public/onboarding/screens/onboarding-general-information/onboarding-general-information.component';
import { OnboardingChooseSectorComponent } from './modules/public/onboarding/screens/onboarding-choose-sector/onboarding-choose-sector.component';
import { OnboardingCreateCredentialsComponent } from './modules/public/onboarding/screens/onboarding-create-credentials/onboarding-create-credentials.component';
import { OnboardingChoseProductComponent } from './modules/public/onboarding/screens/onboarding-chose-product/onboarding-chose-product.component';
import { AuthHomeScreenComponent } from './modules/public/auth/auth-home-screen/auth-home-screen.component';
import { AuthLoginComponent } from './modules/public/auth/auth-login/auth-login.component';
import { RestorePasswordComponent } from './modules/public/auth/restore-password/restore-password.component';
import { AuthVerifyCodeComponent } from './modules/public/auth/screens/auth-verify-code/auth-verify-code.component';
import { CreatePasswordComponent } from './modules/public/auth/screens/create-password/create-password.component';
import { HomeScreenComponent } from './modules/private/home/home-screen/home-screen.component';
import { VerifyInformationComponent } from './modules/private/verify-information/verify-information/verify-information.component';
import { VerifyListDocumentsComponent } from './modules/private/verify-information/screens/verify-information-list-documents/verify-list-documents.component';
import { OffersComponent } from './modules/private/verify-information/screens/verify-information-offers/offers.component';
import { SingleDocumentComponent } from './modules/private/verify-information/screens/verify-information-single-document/single-document.component';
import { VerifyInformationFormComponent } from './modules/private/verify-information/screens/verify-information-form/verify-information-form.component';
import { VerifyInformationDetailStepComponent } from './modules/private/verify-information/screens/verify-information-detail-step/verify-information-detail-step.component';
import { VerifyInformationExternalLinkComponent } from './modules/private/verify-information/screens/verify-information-external-link/verify-information-external-link.component';
import { ListAccountsComponent } from './modules/private/accounts/list-accounts/list-accounts.component';
import { DetailAccountComponent } from './modules/private/accounts/detail-account/detail-account.component';
import { ListBenefitsComponent } from './modules/private/benefits/list-benefits/list-benefits.component';
import { DetailBenefitComponent } from './modules/private/benefits/detail-benefit/detail-benefit.component';
import { WithdrawalCreateNewComponent } from './modules/private/withdrawals/withdrawal-create-new/withdrawal-create-new.component';
import { WithdrawalListComponent } from './modules/private/withdrawals/withdrawal-list/withdrawal-list.component';
import { WithdrawalDetailComponent } from './modules/private/withdrawals/withdrawal-detail/withdrawal-detail.component';
import { AccountsCreateComponent } from './modules/private/accounts/accounts-create/accounts-create.component';
import { LoanCreateComponent } from './modules/private/loan/loan-create/loan-create.component';
import { LoanDetailComponent } from './modules/private/loan/loan-detail/loan-detail.component';
import { BalanceStateComponent } from './modules/private/balance/balance-state/balance-state.component';
import { ProfileUserComponent } from './modules/private/profile/profile-user/profile-user.component';
import { DocumentsComponent } from './modules/private/profile/documents/documents.component';
import {ButtonModule} from "primeng/button";
import {SharedModule} from "./shared/shared.module";
import { SkeletonBottomMenuComponent } from './skeleton/skeleton-bottom-menu/skeleton-bottom-menu.component';
import { SkeletonLeftMenuComponent } from './skeleton/skeleton-left-menu/skeleton-left-menu.component';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";

import localEs from '@angular/common/locales/es'
import localEsMX from '@angular/common/locales/es-MX'
import {registerLocaleData} from "@angular/common";
import {CardModule} from "primeng/card";
import { CardSodComponent } from './modules/private/home/components/card-sod/card-sod.component';
import { CardDefaultComponent } from './modules/private/home/components/card-default/card-default.component';
import { HomeActionsComponent } from './modules/private/home/components/home-actions/home-actions.component';
import { HomeMainSectionComponent } from './modules/private/home/components/home-main-section/home-main-section.component';
import { WithdrawalReceiptComponent } from './modules/private/withdrawals/components/withdrawal-receipt/withdrawal-receipt.component';
import { BenefitCardComponent } from './modules/private/benefits/components/benefit-card/benefit-card.component';
import { AccountsAccountComponent } from './modules/private/accounts/components/accounts-account/accounts-account.component';
import { BalanceBalancesComponent } from './modules/private/balance/components/balance-balances/balance-balances.component';
import { BalanceMovementsComponent } from './modules/private/balance/components/balance-movements/balance-movements.component';
import { ProgressVerificationComponent } from './modules/private/verify-information/components/progress-verification/progress-verification.component';
import { VerificationDocumentsListComponent } from './modules/private/verify-information/components/verification-documents-list/verification-documents-list.component';
import {CoreModule} from "./core/core.module";
import {MessageModule} from "primeng/message";
import { FormComponentComponent } from './modules/private/verify-information/components/form-component/form-component.component';
import { DocumentComponentComponent } from './modules/private/verify-information/components/document-component/document-component.component';
import { ExternalComponentComponent } from './modules/private/verify-information/components/external-component/external-component.component';
import { IneValidationComponent } from './modules/private/verify-information/components/ine-validation/ine-validation.component';
import { SignDocumentComponent } from './modules/private/verify-information/components/sign-document/sign-document.component';
import { OfferComponentComponent } from './modules/private/verify-information/components/offer-component/offer-component.component';
import { ManualValidationComponentComponent } from './modules/private/verify-information/components/manual-validation-component/manual-validation-component.component';
import { HomeCurrentFlowComponent } from './modules/private/home/components/home-current-flow/home-current-flow.component';

registerLocaleData(localEsMX, 'es')
@NgModule({
  declarations: [
    AppComponent,
    OnboardingProcessComponent,
    OnboardingGeneralInformationComponent,
    OnboardingChooseSectorComponent,
    OnboardingCreateCredentialsComponent,
    OnboardingChoseProductComponent,
    AuthHomeScreenComponent,
    AuthLoginComponent,
    RestorePasswordComponent,
    AuthVerifyCodeComponent,
    CreatePasswordComponent,
    HomeScreenComponent,
    VerifyInformationComponent,
    VerifyListDocumentsComponent,
    OffersComponent,
    SingleDocumentComponent,
    VerifyInformationFormComponent,
    VerifyInformationDetailStepComponent,
    VerifyInformationExternalLinkComponent,
    ListAccountsComponent,
    DetailAccountComponent,
    ListBenefitsComponent,
    DetailBenefitComponent,
    WithdrawalCreateNewComponent,
    WithdrawalListComponent,
    WithdrawalDetailComponent,
    AccountsCreateComponent,
    LoanCreateComponent,
    LoanDetailComponent,
    BalanceStateComponent,
    ProfileUserComponent,
    DocumentsComponent,
    SkeletonBottomMenuComponent,
    SkeletonLeftMenuComponent,
    CardSodComponent,
    CardDefaultComponent,
    HomeActionsComponent,
    HomeMainSectionComponent,
    WithdrawalReceiptComponent,
    BenefitCardComponent,
    AccountsAccountComponent,
    BalanceBalancesComponent,
    BalanceMovementsComponent,
    ProgressVerificationComponent,
    VerificationDocumentsListComponent,
    FormComponentComponent,
    DocumentComponentComponent,
    ExternalComponentComponent,
    IneValidationComponent,
    SignDocumentComponent,
    OfferComponentComponent,
    ManualValidationComponentComponent,
    HomeCurrentFlowComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    ButtonModule,
    SharedModule,
    FontAwesomeModule,
    CardModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'es-MX'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
