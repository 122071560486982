import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectEnum',
})
export class SelectEnumPipe implements PipeTransform {
  transform(value: any, options: any, defaultOption = '--'): any {
    const finalOptions = options ? options : [];
    return finalOptions[value] || defaultOption;
  }
}
