import {HttpClient} from "@angular/common/http";
import {inject} from "@angular/core";
import {catchError, map, Observable, of} from "rxjs";
import {IResponse, IResponseArray} from "../interfaces/iresponseArray.interface";
import {environment} from "../../../environments/environment";

export class ApiClass {
  public http: HttpClient
  public path = ''
  constructor() {
    this.http = inject(HttpClient)
  }

  getCollections(filters = {}, url: string = environment.apiUrl, path = this.path): Observable<any> {
    const response: IResponseArray = {error: true, msg: '', data: [], status: 404}
    return this.http.get<IResponseArray>(url + path, {params: filters}).pipe(
      map((r) => {
        response.data = r.data
        response.msg = r.msg
        response.error = r.error
        response.status = r.status
        return response
      }),
      catchError((e) => {
        response.msg = e.error.msg ?? 'Hubo un error de conexión'
        response.data = e.error.data
        response.error = e.error.error
        return of(response)
      })
    )
  }

  getCollection(id: string = '', url: string = environment.apiUrl, path: string = this.path): Observable<any> {
    const response: IResponse = {error: true, msg: '', data: null}
    return this.http.get<IResponse>(url + path + ((id === '' ? '' : '/')) + id ).pipe(
      map((r) => {
        response.data = r.data
        response.msg = r.msg
        response.error = r.error
        return response
      }),
      catchError((e) => {
        response.msg = e.error.msg ?? 'Hubo un error de conexión'
        response.data = e.error.data ?? null
        response.error = e.error.error ?? true
        return of(response)
      })
    )
  }

  deleteCollection(id: string = '', url: string = environment.apiUrl, path: string = this.path): Observable<any> {
    const response: IResponse = {error: true, msg: '', data: null}
    return this.http.delete<IResponse>(url + path + ((id === '' ? '' : '/')) + id ).pipe(
      map((r) => {
        response.msg = r.msg
        response.error = r.error
        return response
      }),
      catchError((e) => {
        response.msg = e.error.msg ?? 'Hubo un error de conexión'
        response.error = e.error.error ?? true
        return of(response)
      })
    )
  }

  postCollection(data: any, subPath = '', url: string = environment.apiUrl): Observable<any> {
    const response: IResponse = {error: true, msg: '', data: null}
    return this.http.post<IResponse>(url + this.path + `${subPath !== '' ? '/'+subPath : ''}`, data).pipe(
      map((r) => {
        response.error = r.error
        response.data = r.data
        response.msg = r.msg
        return response
      }),
      catchError((e) => {
        console.log(e)
        response.msg = e.error.msg ?? 'Hubo un error de conexión'
        response.data = e.error.data ?? null
        response.error = e.error.error ?? true
        return of(response)
      })
    )
  }

  patchCollection(data: any, url: string = environment.apiUrl, path: string = this.path): Observable<any> {
    const response: IResponse = {error: true, msg: '', data: null}
    return this.http.patch<IResponse>(url + path, data).pipe(
      map((r) => {
        response.error = r.error
        response.data = r.data
        response.msg = r.msg
        return response
      }),
      catchError((e) => {
        response.msg = e.error.msg ?? 'Hubo un error de conexión'
        response.data = e.error.data ?? null
        response.error = e.error.error ?? true
        return of(response)
      })
    )
  }


  putCollection(data: any, url: string = environment.apiUrl): Observable<any> {
    const response: IResponse = {error: true, msg: '', data: null}
    return this.http.put<IResponse>(url + this.path, data).pipe(
      map((r) => {
        response.error = r.error
        response.data = r.data
        response.msg = r.msg
        return response
      }),
      catchError((e) => {
        response.msg = e.error.msg ?? 'Hubo un error de conexión'
        response.data = e.error.data ?? null
        response.error = e.error.error ?? true
        return of(response)
      })
    )
  }
}
