import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AccountService} from "../../../../data/services/api/accounts/account.service";
import {Message} from "primeng/api";
import {Router} from "@angular/router";
import {LoaderService} from "../../../../data/services/page/loader.service";

@Component({
  selector: 'app-accounts-create',
  templateUrl: './accounts-create.component.html',
  styleUrls: ['./accounts-create.component.scss']
})
export class AccountsCreateComponent {
  entity: FormGroup
  responseMessage: Message[] = []
  showMessage = false
  isLoading = false
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.entity = this.fb.group({
      account: ['', [Validators.required, Validators.pattern(/^\d{18}$/)]],
      owner: ['', [Validators.required]]
    })
  }

  createAccount() {
    this.isLoading = true
    this.loaderService.showSpinner()
    this.accountService.create(this.entity.value).subscribe(r => {
      this.loaderService.hideSpinner()
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ? 'error': 'success',
          closable: false,
        }
      ]
      if (r.error) this.isLoading = false
      setTimeout(() => {
        this.showMessage = false
        this.responseMessage = []
        this.router.navigate(['/accounts'])
      }, 3000)
    })
  }

}
