
<div class="container-mobile">
  <router-outlet></router-outlet>
  <ng-container *ngIf="authService.getUser">
    <app-skeleton-bottom-menu></app-skeleton-bottom-menu>
  </ng-container>
  <app-skeleton-left-menu *ngIf="showLeftMenu"></app-skeleton-left-menu>
  <app-loader *ngIf="loaderService.spinnerShow"></app-loader>
  <ng-container *ngIf="authService.getUser">
    <div class="whatsapp">
      <a [href]="getWhatsapp" target="_blank"><i class="fa-brands fa-whatsapp"></i></a>
    </div>
  </ng-container>
</div>
<div class="container-high-resolution">
  La plataforma pulpi solo esta disponible en celulares.
</div>
