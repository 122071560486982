import {Component, OnInit} from '@angular/core';
import {
  faBriefcase, faCalendarDays, faCircle,
  faCircleUser,
  faWandMagicSparkles
} from "@fortawesome/free-solid-svg-icons";
import {UserService} from "../../../../data/services/page/user.service";

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit{
  today = new Date()
  icons = {
    profile: faCircleUser,
    cardCompleteProcess: faWandMagicSparkles,
    calendar: faCalendarDays,
    work: faBriefcase,
    circle: faCircle
  }

  constructor(
    public userService: UserService
  ) {

  }

  ngOnInit() {
    this.userService.getData()
  }

  get userIsActive () {
    return this.userService.data?.user.statusVerification !== 'PENDING_VERIFICATION'
  }

}
