import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Message} from "primeng/api";
import {IFlow} from "../../../../../data/interfaces/iflow.interface";
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {LoaderService} from "../../../../../data/services/page/loader.service";

@Component({
  selector: 'app-manual-validation-component',
  templateUrl: './manual-validation-component.component.html',
  styleUrls: ['./manual-validation-component.component.scss']
})
export class ManualValidationComponentComponent {
  responseMessage: Message[] = []
  showMessage = false
  @Input() data!: IFlow
  @Output() onEndProcess = new EventEmitter()
  settings = {description: '', img: ''}

  constructor() {}

  ngOnInit() {
    this.settings = JSON.parse(this.data.properties.settings)
    console.log("hola", this.data)
  }

}
