<app-app-navigation-header
  [title]="''"
  [background]="'#4e488f'"
  [color]="'#fff'"
></app-app-navigation-header>
<app-main-header [title]="'Mi perfil'" [subtitleRight]="'Administra tu información'" [icon]="faUser"></app-main-header>
<div class="row">
  <div class="col-12 px-3 pt-4 pb-2">
    <h2>Datos de usuario</h2>
  </div>
</div>
<div class="row p-1 mb-8">
  <div class="col-12">
    <div class="card">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-2">Nombre:</h4>
          <p>{{authService.getUser?.user?.names?.firstName ?? '-'}} {{authService.getUser?.user?.names?.lastName ?? '-'}}</p>
        </div>
        <div class="col-12">
          <h4 class="mb-2">RFC:</h4>
          <p>{{authService.getUser?.user?.rfc ?? '-'}} </p>
        </div>
        <div class="col-12">
          <h4 class="mb-2">Email:</h4>
          <p>{{authService.getUser?.user?.emailAddress ?? '-'}} </p>
        </div>
        <div class="col-12">
          <h4 class="mb-2">Teléfono:</h4>
          <p>{{authService.getUser?.user?.phoneNumber ?? '-'}} </p>
        </div>
        <div class="col-12">
          <h4 class="mb-2">CURP:</h4>
          <p>{{authService.getUser?.user?.curp ?? '-'}} </p>
        </div>
        <div class="col-12">
          <h4 class="mb-2">Código de referido:</h4>
          <p>{{authService.getUser?.user?.referralCode ?? '-'}} </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 button-block-container">
    <button pButton pRipple class="p-button button-block" (click)="closeSession()">
      <fa-icon [icon]="faLock"></fa-icon> <span class="ml-2">Cerrar sesión</span>
    </button>
  </div>
</div>

