<app-app-navigation-header
  [title]="''"
  [background]="'#4e488f'"
  [color]="'#fff'"
></app-app-navigation-header>
<app-main-header [title]="'Las mejores ofertas'" [subtitleLeft]="'al alcance de tu mano'" [icon]="faTags"></app-main-header>
<div class="list-benefits">
  <ng-container *ngFor="let benefit of benefits">
    <app-benefit-card [background]="benefit.background" [image]="benefit.image" [title]="benefit.title" [actionLabel]="benefit.actionLabel" [url]="benefit.url"></app-benefit-card>
  </ng-container>
</div>

