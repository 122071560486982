<div class="balance-movements my-4">
  <h4 class="mb-2">Movimientos</h4>
  <div class="row">
    <div class="col-12">
      <p class="p-text-secondary text-xs py-2 text-justify">En este apartado podras encontrar tus retiros realizados en pulpi asi como tus créditos aprobado, recuerad que la aplicación de los pagos no es instantanea por lo que puede demorar hasta 30 días en verse reflejado.</p>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-0">
        <p-dropdown [(ngModel)]="typeProduct" [panelStyle]="{'width': '100%' }" [options]="typeProducts" placeholder="Selecciona una producto" optionLabel="label" optionValue="value" [style]="{ background: '#F6F7FC'}" (onChange)="findProducts()"/>
      </div>
    </div>
    <div class="col-12">
      <div class="field field-flex mb-3">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input type="text" pInputText [(ngModel)]="folio" [style]="{ background: '#F6F7FC'}"/>
        </span>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let movement of getMovements">
    <div class="movement">
      <div class="content">
        <div class="movement-date">
          {{ movement.typeProduct === 'Retiro' ? (movement.date | date: 'dd-MMMM-YYYY HH:mm:ss':'es-MX') : (movement.date | date: 'dd-MMMM-YYYY':'es-MX')}}</div>
        <div class="movement-info">
          <p><strong>{{movement.typeProduct}}</strong></p>
        </div>
        <div class="movement-info">
          <p>Folio:<strong class="ml-2">{{movement.folio}}</strong></p>
        </div>
        <div class="movement-info">
          <p>{{(movement.typeProduct === 'Retiro' ? 'Monto dispersado:': 'Monto total a pagar del crédito')}} <strong class="danger">{{movement.dispersedAmount | currency:'MXN'}}</strong></p>
        </div>
        <div class="movement-info">
          <p>Comision: <strong class="danger">{{movement.fee | currency:'MXN'}}</strong></p>
        </div>
        <div class="movement-info">
          <p>Monto pagado: <strong class="blue">{{movement.amountPaid | currency:'MXN'}}</strong></p>
        </div>
        <div class="movement-info">
          <p>Estatus de dispersión:
            <p-tag [value]="movement.statusDispersion | selectEnum: $_movementEnum" [severity]="movement.statusDispersion | selectEnum: $_movementEnumColor"></p-tag>
          </p>
        </div>
        <div class="movement-info">
          <p>
            Estatus de pagado:
            <p-tag [value]="movement.statusPaid | selectEnum: $_movementEnum" [severity]="movement.statusPaid | selectEnum: $_movementEnumColor"></p-tag>
          </p>
        </div>
      </div>
      <div class="action" [routerLink]="movement.typeProduct === 'Retiro' ? '/withdrawal/'+movement.id : '/loan/'+movement.id">
        <fa-icon [icon]="faUpRightFromSquare"></fa-icon>
      </div>
    </div>
  </ng-container>
</div>
