import { Component } from '@angular/core';
import {AuthService} from "./data/services/modules/auth/auth.service";
import {LoaderService} from "./data/services/page/loader.service";
import {UserService} from "./data/services/page/user.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pulpi-v4-app';
  showLeftMenu = false

  constructor(
    public authService: AuthService,
    public loaderService: LoaderService,
    private userService: UserService,
  ) {
  }

  get getWhatsapp() {
    return this.userService.data?.user.endDatePosition !== null ?  'https://wa.me/527299441002?text=Hola+necesito+ayuda' : 'https://wa.me/525580284233?text=Hola+necesito+ayuda'
  }
}
