<div class="container-screen">
  <app-app-navigation-header
    [title]="''"
    [background]="'#4e488f'"
    [color]="'#fff'"
    [backRoute]="'/'"
  ></app-app-navigation-header>
  <app-main-header
    [title]="'Estado de cuenta'"
    [subtitleLeft]="'controla tus finanzas'"
    [icon]="faScaleBalanced"
  ></app-main-header>
  <!--<app-balance-balances></app-balance-balances>-->
  <app-balance-movements></app-balance-movements>
</div>
