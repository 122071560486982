import {Component, OnInit} from '@angular/core';
import {faCircleUser, faCreditCard, faTags} from "@fortawesome/free-solid-svg-icons";
import {ApiBenefitService} from "../../../../data/services/api/benefit/api-benefit.service";

@Component({
  selector: 'app-list-benefits',
  templateUrl: './list-benefits.component.html',
  styleUrls: ['./list-benefits.component.scss']
})
export class ListBenefitsComponent implements OnInit {
  protected readonly faCircleUser = faCircleUser;
  protected readonly faTags = faTags;
  benefits: {title: string; actionLabel: string; background: string; image: string, url: string;}[] = []
  protected readonly faCreditCard = faCreditCard;
  constructor(
    private apiBenefitService: ApiBenefitService
  ) {
  }

  ngOnInit() {
    this.getBenefits()
  }

  getBenefits() {
    this.apiBenefitService.getAll().subscribe(r => {
      const benefits:  {title: string; actionLabel: string; background: string; image: string; url: string;}[] = []
      if(!r.error) {
        r.data.forEach(b => {
          benefits.push({
            title: b.description,
            actionLabel: b.callToAction,
            background: b.color,
            image: b.imageUrl,
            url: b.url
          })
        })
      }
      this.benefits = benefits
    })
  }
}
