import { Component } from '@angular/core';
import {faCreditCard, faHome, faTag, faUser} from "@fortawesome/free-solid-svg-icons";
import {Router} from "@angular/router";
import {UserService} from "../../data/services/page/user.service";

@Component({
  selector: 'app-skeleton-bottom-menu',
  templateUrl: './skeleton-bottom-menu.component.html',
  styleUrls: ['./skeleton-bottom-menu.component.scss']
})
export class SkeletonBottomMenuComponent {
  items: {label: string; icon: any; route: string; paths: string[]}[] = [
    {label: 'Inicio', icon: faHome, route: '/', paths: ['', '/home']},
    {label: 'Cuentas', icon: faCreditCard, route: '/accounts', paths: ['/accounts']},
    {label: 'Para ti', icon: faTag, route: '/benefits', paths: ['/benefits']},
    {label: 'Perfil', icon: faUser, route: '/profile', paths: ['/profile']},
  ]
  constructor(
    private router: Router,
    public userService: UserService

  ) {
  }

  currentRoute(routes: string[]) {
    return routes.includes(this.router.url)
  }

  get getNavigation() {
    this.items = [
      {label: 'Inicio', icon: faHome, route: '/', paths: ['', '/home']}
    ]
    if (this.userService.data?.permissions?.accounts) this.items.push({label: 'Cuentas', icon: faCreditCard, route: '/accounts', paths: ['/accounts']})
    if (this.userService.data?.permissions?.benefits) this.items.push({label: 'Para ti', icon: faTag, route: '/benefits', paths: ['/benefits']})
    if (this.userService.data?.permissions?.profile) this.items.push({label: 'Perfil', icon: faUser, route: '/profile', paths: ['/profile']})
    return this.items
  }
}
