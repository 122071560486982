export enum MOVEMENT_ENUM {
  LIQUIDATED= 'Dispersado',
  PAID = 'Pagado',
  CANCELLED = 'Cancelado',
  DENIED = 'Denegado',
  IN_PROCESS = 'Pendiente de pago',
  APPROVED = 'Aprobado'
}

export enum MOVEMENT_ENUM_COLOR {
  LIQUIDATED= 'primary',
  PAID = 'success',
  CANCELLED = 'danger',
  DENIED = 'danger',
  IN_PROCESS = 'warning',
  APPROVED = 'primary'
}
