import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IFlow} from "../../../../../data/interfaces/iflow.interface";
import {faCircle, faTrashCan, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiFlowDataService} from "../../../../../data/services/api/flow/api-flow-data.service";
import {Message} from "primeng/api";
import {LoaderService} from "../../../../../data/services/page/loader.service";

@Component({
  selector: 'app-document-component',
  templateUrl: './document-component.component.html',
  styleUrls: ['./document-component.component.scss']
})
export class DocumentComponentComponent {
  responseMessage: Message[] = []
  showMessage = false
  @Input() data!: IFlow
  @Output() onEndProcess = new EventEmitter()
  entity: FormGroup
  hasFile = false
  file!: File
  constructor(
    private fb: FormBuilder,
    private flowDataService: ApiFlowDataService,
    private loaderService: LoaderService
  ) {
    this.entity = this.fb.group({
      file: ['', [Validators.required]]
    })
  }

  protected readonly faCircle = faCircle;
  protected readonly faUpload = faUpload;
  protected readonly faTrashCan = faTrashCan;
  select(event: any){
    this.hasFile = true
    console.log(event)
    this.file = event.files[0]
  }

  clear() {
    this.hasFile = false
  }

  save() {
    const form = new FormData()
    form.append('document', this.file)
    console.log(this.data.id, this.data.userDocumentId)
    form.append('idDocument', this.data.id)
    if(this.data.userDocumentId) form.append('idStage', this.data.userDocumentId)
    this.loaderService.showSpinner()
    this.flowDataService.uploadDocument(form).subscribe(r => {
      console.log(r)
      this.loaderService.hideSpinner()
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ?  'error': 'success',
          closable: false,
        }
      ]
      if (!r.error) {
        setTimeout(() => {
          this.onEndProcess.emit(true)
        }, 4000)
      }
    })
    console.log(this.file)
  }

}
