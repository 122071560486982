import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService, Message} from "primeng/api";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {WorkerService} from "../../../../data/services/api/worker.service";
import {UserService} from "../../../../data/services/page/user.service";
import {PushingService} from "../../../../data/services/api/flow/pushing.service";
import {Router} from "@angular/router";
import {LoaderService} from "../../../../data/services/page/loader.service";

@Component({
  selector: 'app-loan-create',
  templateUrl: './loan-create.component.html',
  styleUrls: ['./loan-create.component.scss'],
  providers: [ConfirmationService]
})
export class LoanCreateComponent implements OnInit {
  entity: FormGroup
  responseMessage: Message[] = []
  showMessage = false
  isLoading = false
  productsSelection: {label: string; value: string}[] = []
  products: {
    id: string;
    name: string;
    mainPoints: string[]
  }[] = []
  constructor(
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private workerService: WorkerService,
    public userService: UserService,
    private pushingService: PushingService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.entity = this.fb.group({
      flowId: ['', [Validators.required]]
    })
  }

  ngOnInit() {
    this.getProducts()
  }

  getProducts() {
    this.workerService.getProducts().subscribe(r => {
      console.log(r)
      if(!r.error) {
        this.products = r.data
        this.productsSelection = r.data.map(p => {
          return {
            label: p.name,
            value: p.id
          }
        })
      }
    })
  }

  get getCorrectProduct () {
    let mainPoints = null
    if (this.products) mainPoints = this.products.find (p => p.id === this. entity.value.desireProduct)
    return mainPoints ? mainPoints.mainPoints :[]
  }

  sendData() {
    this.loaderService.showSpinner()
    this.pushingService.create(this.entity.value).subscribe(r => {
      this.loaderService.hideSpinner()
      this.responseMessage = [
        {
          summary: r.msg,
          severity: r.error ?  'error': 'success',
          closable: false,
        }
      ]
      if (!r.error) {
        this.router.navigate(["/verify-documents"])
      }
    })
  }

  protected readonly faCheckCircle = faCheckCircle;
}
