<app-app-navigation-header
  [backRoute]="'/login'"
  title="Restablecer contraseña"
></app-app-navigation-header>
<form [formGroup]="entity">
  <div class="login-form">
    <img src="assets/media/images/logo/logo.png">
    <h3>Restablecer contraseña</h3>
    <div class="message">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="false" [escape]="false"></p-messages>
    </div>
    <div class="field field-flex">
      <label for="emailAddress">Email:</label>
      <input type="text" pInputText id="emailAddress" formControlName="emailAddress" placeholder="user@pulpi.mx" required />
      <ng-container *ngIf="entity.get('emailAddress')?.invalid && (entity.get('emailAddress')?.dirty || entity.get('emailAddress')?.touched)">
        <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['required']">El campo es obligatorio.</small>
        <small class="p-error" *ngIf="entity.get('emailAddress')?.errors?.['pattern']">El valor no es un email válido.</small>
      </ng-container>
    </div>
  </div>
  <div class="login-button button-block-container">
    <button pButton pRipple label="Restablecer contraseña" class="p-button-primary button-block mb-3" (click)="resetPassword()" [disabled]="entity.invalid"></button>
    <button pButton pRipple label="Contáctanos por Whatsapp" class="p-button-primary p-button-outlined button-block mb-3" (click)="whatsapp()"></button>
  </div>
</form>
