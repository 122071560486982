<div class="container">
    <app-app-navigation-header
      [title]="'Nuevo crédito'"
      [backRoute]="'/'"
    ></app-app-navigation-header>
    <div class="message mx-3">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
    </div>
    <ng-container *ngIf="userService.data?.userHasRequestInProcess === false; else elseLoan">
      <form [formGroup]="entity">
        <div class="row p-3">
          <div class="col-12 text-center mb-5">
            <p>¿Qué producto te interesa?</p>
          </div>
          <div class="col-12">
            <div class="field field-flex mb-2">
              <label for="flowId">Selecciona un producto:*</label>
              <p-dropdown formControlName="flowId" [panelStyle]="{'width': '100%' }" [options]="productsSelection" placeholder="Selecciona una opción" optionLabel="label" optionValue="value"/>
              <ng-container *ngIf="entity.get('flowId')?.invalid && (entity.get('flowId')?.dirty || entity.get('flowId')?.touched)">
                <small class="p-error" *ngIf="entity.get('flowId')?.errors?.['required']">El campo es obligatorio.</small>
              </ng-container>
            </div>
          </div>
          <div class="col-12 mb-5">
            <ng-container *ngFor="let mainPoint of getCorrectProduct">
              <div class="main-point">
                <fa-icon [icon]="faCheckCircle"></fa-icon>
                <span>{{mainPoint}}</span>
              </div>
            </ng-container>
          </div>
          <div class="col-12 flex flex-column">
            <button pButton pRipple label="Crear crédito" class="p-button-primary button-block mb-3" (click)="sendData()" [disabled]="entity.invalid"></button>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-template #elseLoan>
      <div class="row p-3">
        <div class="col-12">
          <p class="my-3">Ya cuentas con un flujo en proceso, para crear uno nuevo, debes de concluir el anterior o cancelarlo.</p>
        </div>
        <div class="col-12 flex flex-column">
          <button pButton pRipple label="Ver proceso" class="p-button-primary button-block mb-3" [routerLink]="'/verify-documents'"></button>
        </div>
      </div>
    </ng-template>
</div>
