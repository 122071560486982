<ng-container *ngIf="!startedProcess; else elseProcess">
  <div class="grid px-4">
    <div class="col-12 my-4">
      <p>
        Para continuar con la firma del documento, asegurate de tener una buena conexión, realizar la firma como se ve en tu identificación oficial y por último, dar click en finalizar firma y esperar a que el proceso concluya.
      </p>
    </div>
    <div class="col-12 mt-2 comment-rejected" *ngIf="data.comment && data.comment !== ''">
      Motivo de rechazo: <strong>{{data.comment}}</strong>
    </div>
    <div class="col-12">
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      </div>
    </div>
    <div class="col-12">
      <div class="img img-start"></div>
    </div>
    <div class="col-12 button-block-container my-5">
      <button pButton pRipple label="Empezar proceso" icon="fa-solid fa-fingerprint p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="start()" [disabled]="!startButtonIsAvailable"></button>
    </div>
  </div>
</ng-container>
<ng-template #elseProcess>
  <div class="grid px-4">
    <div class="col-12 my-4">
      <p>
        Si has completado corectamente el proceso de la firma de documento, da click en he concluido el proceso.
      </p>
    </div>

    <div class="col-12">
      <div class="img img-complete"></div>
    </div>
    <div class="col-12">
      <div class="message">
        <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      </div>
    </div>
    <div class="col-12 button-block-container my-5">
      <button pButton pRipple label="He concluido el proceso" icon="fa-solid fa-fingerprint p-button-icon-center" class="p-button text-center justify-content-center button-block" (click)="validateUserSign()"></button>
    </div>
  </div>
</ng-template>
