import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";
import {IWithdrawal, IWithdrawalResume} from "../../../interfaces/iwithdrawal.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiWithdrawalService extends ApiClass{

  constructor() {
    super()
    this.path = 'withdrawal'
  }

  create(data: null) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IWithdrawal}))
  }

  getAll() {
    return this.getCollection().pipe(map((r) => r as {error: boolean; msg: string; data: IWithdrawalResume[]}))
  }
  getById(id: string) {
    return this.getCollection(id).pipe(map((r) => r as {error: boolean; msg: string; data: IWithdrawalResume}))
  }
}
