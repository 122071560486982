import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {Router} from "@angular/router";
import {IUserAuthenticated} from "../../../interfaces/iuser-authenticated.interface";
import {ApiClass} from "../../../class/api-class";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiClass {
  private currentUserSubject: BehaviorSubject<IUserAuthenticated | null>
  public currentUser: Observable<IUserAuthenticated | null>
  public constLocalUserVariable = 'pulpi-user-app'
  constructor(
    private router: Router
  ) {
    super();
    this.path = 'auth'
    this.currentUserSubject = new BehaviorSubject<IUserAuthenticated | null>(
      JSON.parse(<string>localStorage.getItem(this.constLocalUserVariable))
    )
    this.currentUser = this.currentUserSubject.asObservable()
  }

  public get getUser(): IUserAuthenticated | null{
    return this.currentUserSubject.value
  }

  login(data: {email: string, password: string}) {
    return this.postCollection(data).pipe(map((r) => r as {error: boolean; msg: string; data: IUserAuthenticated}))
  }

  resetPassword(data: {email: string, password: string}) {
    return this.postCollection(data, 'resetPassword').pipe(map((r) => r as {error: boolean; msg: string; data: boolean}))
  }

  logout() {
    localStorage.removeItem(this.constLocalUserVariable)
    this.currentUserSubject.next(null)
    this.router.navigate(['/'])
  }

  public get getToken(): string {
    return this.currentUserSubject.value?.token ?? ''
  }

  updateUserLocalData(user: IUserAuthenticated) {
    if(user) {
      localStorage.setItem(this.constLocalUserVariable, JSON.stringify(user))
      this.currentUserSubject.next(user)
    }
  }
}
