<div class="container-screen">
  <app-app-navigation-header
    [background]="'#4e488f'"
    [color]="'#fff'"
    [backRoute]="'/balance'"
  ></app-app-navigation-header>
  <ng-container *ngIf="data; else elseLoan">
  <app-main-header [title]="'Detalle del crédito'" [subtitleRight]="data.folio" [icon]="faFileInvoice"></app-main-header>
  <div class="row px-4 py-2 mb-3">
    <div class="col-12 data-values-container">
      <h5 class="mb-2">Fechas</h5>
      <div class="data-values">
        <p>Fecha de solicitud</p>
        <p>{{data.creationDate | date: 'dd-MM-YYYY'}}</p>
      </div>
      <div class="data-values">
        <p>Fecha de dispersión</p>
        <p>{{data.date | date: 'dd-MM-YYYY'}}</p>
      </div>
      <div class="data-values">
        <p>Fecha de primer letra</p>
        <p>{{data.installments[0].dueDate | date: 'dd-MM-YYYY' }}</p>
      </div>
      <div class="data-values">
        <p>Fecha de última letra</p>
        <p>{{data.installments[data.installments.length - 1].dueDate | date: 'dd-MM-YYYY' }}</p>
      </div>
      <!--<div class="data-values">
        <p>Fecha de proxima letra</p>
        <p>12-01-1995</p>
      </div>-->
      <h5 class="mb-2">Montos</h5>
      <div class="data-values">
        <p>Monto solicitado</p>
        <p>{{data.amount | currency:'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Comisión por apertura</p>
        <p>{{data.creationFee | currency: 'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Monto de intereses</p>
        <p>{{getAllInterests | currency: 'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Total a pagar</p>
        <p>{{(data.installmentAmount * data.installmentCount) | currency: 'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Numero de letras / Letras liquidadas</p>
        <p>{{getAdvanceInstallments}}</p>
      </div>
      <div class="data-values">
        <p>Monto de la letra</p>
        <p>{{data.installmentAmount | currency: 'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Total pagado</p>
        <p>{{getTotalPaid | currency: 'MXN'}}</p>
      </div>
      <div class="data-values">
        <p>Pendiente de pagar</p>
        <p>{{getPendingToPaid | currency: 'MXN'}}</p>
      </div>
      <h5 class="mb-2">Estatus</h5>
      <div class="data-values">
        <p>Estatus de crédito</p>
        <p-tag [value]="'Aprobado'" [severity]="'success'"></p-tag>
      </div>
      <div class="data-values">
        <p>Estatus de pagado</p>
        <p-tag [value]="(getPendingToPaid <= 0) ? 'Pagado': 'Pendiente'" [severity]="(getPendingToPaid <= 0) ? 'primary': 'warning'"></p-tag>
      </div>
    </div>
    <div class="col-12 text-center px-4 balance-button-container">
      <p-messages [(value)]="responseMessage" [enableService]="showMessage" [closable]="true" [escape]="false" [showTransitionOptions]="'500ms'" [hideTransitionOptions]="'500ms'"></p-messages>
      <button pButton pRipple label="Descargar estado de cuenta"  icon="fa-solid fa-download" class="p-button-primary button-block mb-3" (click)="downloadBalance()"></button>
    </div>
  </div>

  <div class="col-12 px-4">
    <h4 class="mb-2">Letras del crédito</h4>
    <p class="text-sm mb-3">Visualiza el estado de cada letra del crédito.</p>
  </div>
    <ng-container *ngFor="let installment of data.installments">
      <div class="col-12 px-4">
        <div class="installment">
          <div class="installment-data">
            <p>Número de letra</p>
            <p>{{installment.index + 1}}</p>
          </div>
          <div class="installment-data">
            <p>Monto de la letra</p>
            <p>{{((installment.principalAmount + installment.revenueAmount) | currency: 'MXN')}}</p>
          </div>
          <div class="installment-data">
            <p>Monto pagado</p>
            <p>{{installment.collectedAmount | currency: 'MXN'}}</p>
          </div>
          <div class="installment-data">
            <p>Fecha de exigibilidad</p>
            <p>{{installment.dueDate | isoDateEnum}}</p>
          </div>
          <div class="installment-data">
            <p>Estatus de pagado</p>
            <p-tag [value]="(installment.collectedAmount >= (installment.principalAmount +  installment.revenueAmount - 0.1)) ? 'Pagado': 'Pendiente de pago'" [severity]="(installment.collectedAmount >= (installment.principalAmount +  installment.revenueAmount - 0.1)) ?'success': 'warning'"></p-tag>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #elseLoan>
    <div class="col-12 text-center">
      No se encontro el detalle del crédito
    </div>
  </ng-template>
</div>
