import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-onboarding-create-credentials',
  templateUrl: './onboarding-create-credentials.component.html',
  styleUrls: ['./onboarding-create-credentials.component.scss']
})
export class OnboardingCreateCredentialsComponent {
  @Input() entity!: FormGroup
  @Output() onSendData = new EventEmitter()
  sendData() {
    this.onSendData.emit()
  }
  whatsapp() {
    window.open('https://wa.me/525580284233?text=Hola+necesito+ayuda','_blank')
  }
}
