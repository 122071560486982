import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  currentMessage = 'Enviando solicitud'
  messages: string[] = [
    'Realizando operación',
    'Ya casi terminamos',
    'Espera un momento más',
    'Unos segundos más',
    'Estamos por terminar'
  ]

  ngOnInit() {
    this.generateMessage()
  }

  generateMessage () {
    setTimeout(() => {
      this.currentMessage = this.messages[0]
    }, 1500)
    setTimeout(() => {
      this.currentMessage = this.messages[1]
    }, 4000)
    setTimeout(() => {
      this.currentMessage = this.messages[2]
    }, 6000)
    setTimeout(() => {
      this.currentMessage = this.messages[3]
    }, 10000)
    setTimeout(() => {
      this.currentMessage = this.messages[4]
    }, 15000)
  }

}
