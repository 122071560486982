import { Injectable } from '@angular/core';
import {ApiClass} from "../../../class/api-class";
import {map} from "rxjs";
import {IBenefit} from "../../../interfaces/ibenefit.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiBenefitService extends ApiClass{

  constructor() {
    super()
    this.path = 'benefit'
  }

  getAll() {
    return this.getCollection().pipe(map((r) => r as {error: boolean; msg: string; data: IBenefit[]}))
  }
}
